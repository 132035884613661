import Error from "./Error.ts";

export interface ErrorResponse {
    data: {
        errors: {
            [key: string]: string[]
        },
        message: string
    },
    status: number
}

export interface SlideErrorsInterface {
    addError(error: Error): void
    resetBlockError(slideId: string, blockIdentifier: string): void
    resetSlideError(slideId: string): void
    getBlockErrors(slideId: string, blockIdentifier: string): Error[],
    getSlideErrors(slideId: string,): Error[]
}

export default class SlideErrors implements SlideErrorsInterface {
    protected errors: Error[] = [];

    processErrorResponse(errorResponse: ErrorResponse, slideId: string): void {
        if (errorResponse?.status === 422) {
            for (const identifier in errorResponse.data.errors) {
                this.addError(new Error(
                    slideId,
                    errorResponse.data.errors[identifier],
                    identifier
                ));
            }

            return;
        }

        this.addError(new Error(
            slideId, ['Something went wrong. Please try again']
        ));
    }

    addError(error: Error): void {
        this.errors.push(error);
    }

    resetBlockError(slideId: string, blockIdentifier: string) {
        this.errors = this.errors.filter((error: Error) => error.slideId !== slideId && error.blockIdentifier !== blockIdentifier);
    }

    resetSlideError(slideId: string) {
        this.errors = this.errors.filter((error: Error) => error.slideId !== slideId);
    }

    getBlockErrors(slideId: string, blockIdentifier: string): Error[] {
        return this.errors.filter((error: Error) => error.slideId == slideId && error.blockIdentifier === blockIdentifier);
    }

    getSlideErrors(slideId: string): Error[] {
        return this.errors.filter((error: Error) => error.slideId == slideId);
    }
}