<template>
    <div @click="handleClick" class="hover:fb-border-blue-500 hover:fb-border-sol-primary fb-bg-white fb-cursor-pointer fb-flex fb-flex-col fb-items-center fb-px-6 fb-py-2 fb-border fb-text-sol-black fb-rounded-lg fb-shadow fb-w-28">
        <span class="fb-text-lg fb-font-medium">{{formattedTime}}</span>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";

const props = defineProps({
    time: {
        type: [String, Number],
        required: true
    },
});

const emit = defineEmits(['click'])

const formattedTime = computed(() => {
   return formatTime(props.time)
})

const handleClick = () => {
    emit('click', formattedTime.value)
}

const formatTime = (time) => `${time % 12 || 12} ${time < 12 ? "AM" : "PM"}`
</script>