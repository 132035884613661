<template>
    <div>
        <div class="fb-flex fb-flex-col fb-items-center fb-gap-y-6">
            <div v-if="instance.label && instance.labelPosition === LoadingSpinnerLabelPosition.Top"
                 class="fb-text-base"
            >
                {{ instance.label }}
            </div>
            <div class="loading-spinner-block fb-inline-block fb-relative"
                 :style="spinnerStyles"
            >
                <div class="fb-absolute fb-block fb-box-border fb-rounded-full lds-ring-part"
                     :style="spinnerChildStyles(1)"
                />
                <div class="fb-absolute fb-block fb-box-border fb-rounded-full lds-ring-part" :style="spinnerChildStyles(2)" />
                <div class="fb-absolute fb-block fb-box-border fb-rounded-full lds-ring-part" :style="spinnerChildStyles(3)" />
                <div class="fb-absolute fb-block fb-box-border fb-rounded-full lds-ring-part" :style="spinnerChildStyles" />
            </div>
            <div v-if="instance.label && instance.labelPosition === LoadingSpinnerLabelPosition.Bottom">
                {{ instance.label }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoadingSpinner, {
    DefaultSpinnerColors,
    LoadingSpinnerLabelPosition,
    LoadingSpinnerSize
} from "@flow-builder/core/src/Blocks/Core/Display/LoadingSpinner.ts";
import {computed} from "vue";
import {DEFAULT_THEME_COLORS} from "@flow-builder/core/src/Styling/Theme.ts";

interface Props {
    instance: LoadingSpinner,
}
const props = defineProps<Props>();

const themeColors = DEFAULT_THEME_COLORS;

const spinnerStyles = computed(() => {
    return {
        width: spinnerWidthAndHeight(),
        height: spinnerWidthAndHeight(),
    }
});

const spinnerChildStyles = (child: number) => {
    return {
        width: spinnerWidthAndHeight(),
        height: spinnerWidthAndHeight(),
        border: spinnerChildBorder(),
        borderColor: spinnerChildBorderColor(),
        animationDelay: spinnerChildAnimationDelay(child),
    }
};

const spinnerWidthAndHeight = () => {
    switch(props.instance.spinnerSize) {
        case LoadingSpinnerSize.Large:
            return '64px'
        case LoadingSpinnerSize.Medium:
            return '48px'
        case LoadingSpinnerSize.Small:
        default: {
            return '32px'
        }
    }
}

const spinnerChildBorder = () => {
    const styles = { width: '', color: '' }
    switch(props.instance.spinnerSize) {
        case LoadingSpinnerSize.Large:
            styles.width = '8px';
            break;
        case LoadingSpinnerSize.Medium:
            styles.width = '6px';
            break;
        case LoadingSpinnerSize.Small:
        default: {
            styles.width = '4px';
            break;
        }
    }
    styles.color = getRgbColor(props.instance.spinnerColor) ?? DefaultSpinnerColors.Spinner;

    return `${styles.width} solid ${styles.color}`;
}

const spinnerChildBorderColor = () => {
    const spinnerColor = getRgbColor(props.instance.spinnerColor) ?? DefaultSpinnerColors.Spinner;
    const trackColor = getRgbColor(props.instance.trackColor) ?? DefaultSpinnerColors.Track;

    return `${spinnerColor} ${trackColor} ${trackColor} ${trackColor}`;
}

const spinnerChildAnimationDelay = (child: number) => {
    switch (child) {
        case 1:
            return '-0.45s';
        case 2:
            return '-0.3s';
        case 3:
            return '-0.15s';
        default:
            return '';
    }
}

const getRgbColor = (themeColorName: string): string|null => {
    const themeColor = themeColors[camelizeColorName(themeColorName)];
    return themeColor
        ? `rgba(${themeColor.primary.toRGBAString()})`
        : null;
}

const camelizeColorName = (themeColorName: string): string => {
    const parts: string[] = themeColorName.split(/-/g);

    return parts.reduce((output, part, index) => {
        const newPart = index !== 0 && /^[a-z]/.test(part)
            ? `${part[0].toUpperCase()}${part.slice(1)}`
            : part;
        return output + newPart;
    }, '');
}

</script>