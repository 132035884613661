import {SlideEventPayload, TrackEvents} from "../CalculatorEventService.ts";

export class PageView implements TrackEvents {
    trackEvent(flowName: string, slideName: string, customEventPayload?: SlideEventPayload): void {
        // @ts-ignore
        if (import.meta.env.environment !== 'production' && !window.dataLayer && !window['optimizelyEdge']) {
            const debugEvent = customEventPayload ?? {
                'event': 'debugVirtualPageview',
                'vpv': `/${flowName}/${slideName}`,
            };
            console.log(`Debug: GTM event fired`, debugEvent);
        }
        else {
            // TODO: Break into Separate GTM service
            //@ts-ignore
            if (window.dataLayer !== undefined && window.dataLayer !== null) {
                const eventPayload = customEventPayload ?? {
                    'event': 'virtualPageview',
                    'vpv': `/${flowName}/${slideName}`,
                }
                //@ts-ignore
                window.dataLayer.push(eventPayload);
            }

            // TODO: Break into Separate Optimizely service
            // @ts-ignore
            if (window['optimizelyEdge'] !== undefined && window['optimizelyEdge']  !== null) {
                // @ts-ignore
                window['optimizelyEdge'] .push({
                    'type': 'event',
                    'eventName': `calculator_${slideName}`,
                });
            }
        }
    }

}