import {FlowJson, useFirebaseV2, FlowResponse} from "@flow-builder/core/src/Services/firestore-v2-service.ts";

export type ClientFlowResponse = {
    flowJson: FlowJson,
    flowKey: string,
    deletedFlow: boolean,
}
export async function getFlow(flowId: string, revisionId: string | null, workingBranch: boolean): Promise<ClientFlowResponse|null> {
    const firebaseService = useFirebaseV2({
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_FIREBASE_APP_ID,
        environmentPath: import.meta.env.VITE_FIREBASE_ENVIRONMENT
    });

    if (!revisionId) {
        if (!flowId) throw new Error("No Flow ID or Revision ID provided.");

        revisionId = await firebaseService.getProductionRevisionFromFlowId(flowId);

        if (!revisionId) {
            throw new Error("No Production Revision was found for the supplied Flow ID");
        }
    }

    const flowResponse = await firebaseService.getFlowRevisionById(revisionId, workingBranch)
        .catch((err: Error) => {
            console.error(err);
            return null;
        });

    if (flowResponse) {
        const deletedFlow = revisionIsSoftDeleted(flowResponse);

        return {
            flowJson: flowResponse.flowJson,
            flowKey: flowResponse.flowKey,
            deletedFlow,
        }
    }
    else return null;
}

const revisionIsSoftDeleted = ({ flowMeta }: FlowResponse): boolean => {
    //@ts-ignore
    return !!(flowMeta?.deleted_ttl?.seconds);
}
