import {useFlowStore} from "../Stores/flow.ts";

export interface Transition {
    in(el: HTMLElement): Promise<any>

    out(el: HTMLElement): Promise<any>
}

export class FadeTransition implements Transition {
    protected fadeInterval = 0.025;

    constructor(fadeInterval: number | null = null) {
        if (fadeInterval) this.fadeInterval = fadeInterval;
    }

    in(el: HTMLElement): Promise<void> {
        const flowStore = useFlowStore();
        if(flowStore.showLoading) {
            return new Promise(resolve => {
                el.style.opacity = (1).toString();
                resolve();
            })
        }

        return new Promise(resolve => {
            let opacity = 0;
            el.style.opacity = opacity.toString();

            const fade = () => {
                if((opacity += this.fadeInterval) > 1) {
                    el.style.opacity = opacity.toString();
                    resolve();
                } else {
                    el.style.opacity = opacity.toString();

                    requestAnimationFrame(fade);
                }
            };

            fade();
        });
    }

   out(el: HTMLElement): Promise<void> {
       const flowStore = useFlowStore();
       if(flowStore.showLoading) {
           return new Promise(resolve => {
               el.style.opacity = (1).toString();
               resolve();
           })
       }

       return new Promise(resolve => {
           let opacity = 1;

           el.style.opacity = opacity.toString();

           const fade = () => {
               if((opacity -= this.fadeInterval) < 0) {
                   el.style.opacity = opacity.toString();
                   resolve();
               } else {
                   el.style.opacity = opacity.toString();
                   requestAnimationFrame(fade);
               }
           };

           fade();
       });
    }
}