export default class Address {
    constructor(
        public streetNumber: string = '',
        public streetName: string = '',
        public city: string = '',
        public zipCode: string = '',
        public state: string = '',
        public country: string = '',
        public latitude: number = 0,
        public longitude: number = 0,
        public fullAddress: string = '',
        public streetAddress: string = ''
    ){}
}