import {usePayloadStore} from "../Stores/payload.ts";
import BlockPayload from "../Payload/BlockPayload.ts";

export function usePayload() {
    const payloadStore = usePayloadStore();

    const setInitialPayload = (slideId: string, payload: Object, blockId: string) => {
        payloadStore.flowPayload?.setInitialBlockPayload(new BlockPayload(slideId, payload, blockId));
    }

    const setInitialBlockPayload = (blockPayload: BlockPayload) => {
        payloadStore.flowPayload?.setInitialBlockPayload(blockPayload);
    }

    return {
        setInitialPayload,
        setInitialBlockPayload
    }
}