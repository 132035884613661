<template>
    <div ref="fadeContainer"
         :class="currentRendererClasses"
         :style="currentRendererStyles"
    >
        <BlockRenderer
            v-for="block in nestedTree"
            :key="block.id"
            :id="block.id"
            :block="block"
        />
    </div>
</template>

<script setup lang="ts">
import BlockRenderer from "../../WebRenderer/BlockRenderer.vue";
import {TransitionType, useFlowStore} from "../../../Stores/flow.ts";
import {onMounted, Ref, ref, watch} from "vue";
import {FadeTransition} from "../../../Services/Transition.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import calculatorEventService from "../../../Services/Analytics/CalculatorEventService.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {
    useBackgroundSlideClasses,
    useBackgroundStyles
} from "@flow-builder/core/src/Components/Renderer/Composables/useStylesTransformer.ts";
import {FlowStyle} from "@flow-builder/core/src/Flows/Flow.ts";

interface Props {
    nestedSlide: Slide,
}
const props = defineProps<Props>();

const flowStore = useFlowStore();
const fadeContainer: Ref<HTMLElement | null> = ref(null);

const nestedTree: Ref<Block[]> = ref([]);
const currentRendererStyles: Ref<Object> = ref([]);
const currentRendererClasses: Ref<Object> = ref([]);

const initialize = () => {
    nestedTree.value = props.nestedSlide.hierarchy;
    updateContainerStyles();
};

onMounted(() => initialize());

watch(() => props.nestedSlide, (newValue: Slide, oldValue: Slide) => {
    if (flowStore.transitionType !== TransitionType.TemplateToTemplate) return;
    flowStore.startRendererTransition();

    const transition = new FadeTransition();

    if (!oldValue) {
        nestedTree.value = newValue.hierarchy;
        updateContainerStyles();
        flowStore.updateGlobalStyleVariables();
        flowStore.fireSlideLoadedEvent(newValue);
        calculatorEventService.trackEvents(flowStore.sliderService.currentSlide?.name ?? '');
    } else {
        // For template-template, resetting scroll height during the fade-out seems to look better
        setTimeout(() => resetScrollHeight(), 50);

        transition.out(fadeContainer.value as HTMLElement).then(async () => {
            nestedTree.value = newValue.hierarchy;
            await new Promise(res => setTimeout(() => res(), 10));

            updateContainerStyles();
            flowStore.updateGlobalStyleVariables();
            await flowStore.checkImagesArePreloaded();

            transition.in(fadeContainer.value as HTMLElement);

            flowStore.finishRendererTransition();
            flowStore.fireSlideLoadedEvent(newValue);
            calculatorEventService.trackEvents(flowStore.sliderService.currentSlide?.name ?? '');
        });
    }
});

const updateContainerStyles = () => {
    updateStyles();
    updateClasses();
}

const updateStyles = () => {
    const targetStyles = props.nestedSlide.slideStyles;

    if (!targetStyles) return;

    const styles = {};

    useBackgroundStyles(targetStyles, styles);

    if (targetStyles.borderRadius > 0)
        Object.assign(styles, { borderRadius: `${targetStyles.borderRadius}px` });

    currentRendererStyles.value = styles;
};

const updateClasses = () => {
    const targetStyles = props.nestedSlide.slideStyles;
    if (!targetStyles) return;

    const classes = [];

    useBackgroundSlideClasses(targetStyles, classes, flowStore.flow?.getStyle() as FlowStyle);

    currentRendererClasses.value = classes;
};

const resetScrollHeight = () => {
    document.scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>
