<template>
    <div class="fb-flex fb-flex-row fb-text-gray-500 fb-cursor-pointer fb-items-center" @click="checked = !checked">
        <div class="fb-rounded fb-border fb-h-4 fb-w-4 fb-flex fb-items-center fb-justify-center fb-text-white"
            :class="{'b-border-sol-light-gray': !checked, 'fb-border-blue-500 fb-bg-blue-500': checked}"
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="fb-w-4 fb-h-4" v-if="checked">
                <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="fb-ml-2 fb-text-sm"
            :style="innerStyles"
        >
            {{ label }}
        </div>
    </div>
</template>

<script setup lang="ts">
import {onMounted, ref, Ref, watch} from "vue";
import {usePayloadStore} from "../../../Stores/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {useFlowStore} from "../../../Stores/flow.ts";
import Checkbox from "@flow-builder/core/src/Blocks/Core/Inputs/Checkbox.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {onBeforeMount} from "vue";
import {usePayload} from "../../../Composables/payload.ts";

const props = defineProps({
    label: {
        type: String,
        default: ""
    },
    defaultValue: {
        type: Boolean,
        default: false
    },
    instance: {
        type: Object,
        default: () => ({})
    },
    innerStyles: {
        type: Object,
        default: () => ({})
    },
});

const checked: Ref<Boolean> = ref(false);
const payloadStore = usePayloadStore();
const flowStore = useFlowStore();

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [(props.instance as Checkbox).backendIdentifier]: checked.value
    },
    (props.instance as Block).id
);

const initialize = () => {
    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        (props.instance as Checkbox).id
    );

    checked.value = payload ? payload.payload[(props.instance as Checkbox).backendIdentifier] : props.defaultValue ?? false;
}

onMounted(() => initialize());

watch(() => checked.value, () => payloadStore.flowPayload?.setBlockPayload(buildPayload()));
</script>