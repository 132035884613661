<template>
    <div class="fb-flex fb-flex-col">
        <div class="fb-text-gray-500 fb-text-sm fb-font-bold fb-mb-1" v-if="(instance as AddressField).label.trim().length > 0">
            {{ (instance as AddressField).label }}
        </div>
        <input
            class="fb-flex-1 fb-border fb-border-gray-200 fb-px-4 fb-py-3"
            :disabled="(instance as AddressField).disabled"
            type="text"
            ref="container"
            :placeholder="(instance as AddressField).placeholder"
            v-model="value"
        />
    </div>
</template>

<script setup lang="ts">

import AddressField from "@flow-builder/core/src/Blocks/Core/Inputs/AddressField.ts";
import {onBeforeMount, onMounted, ref, Ref} from "vue";
import {Google, GoogleMapsAPI} from "@flow-builder/core/src/Services/External/Google.ts";
import Address from "../../../Payload/Address.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import Block, {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {usePayload} from "../../../Composables/payload.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {useEngineStore} from "../../../Stores/engines.ts";

const googleService: Ref<GoogleMapsAPI|null> = ref(null);
const container = ref(null);
const value = ref(null);
const autocomplete = ref(null);

const props = defineProps({
    instance: {
        type: Object,
        default: null
    }
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();
const engineStore = useEngineStore();

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload({}));
});

onMounted(() => initialize());

const initialize = () => {
    const service = new Google();
    let payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        (props.instance as AddressField).id
    );

    if (!payload) {
        const address = buildAddressFromEngineInputs();

        if (address) {
            payload = buildPayload(address);

            payloadStore.flowPayload?.setBlockPayload(payload);
        }
    }

    value.value = payload ? payload.payload['full_address'] : (props.instance as AddressField).value ?? null;

    service.loadService().then(() => {
        googleService.value = service.getService();
        initializeAutocomplete();
    });
};

const initializeAutocomplete = () => {
    autocomplete.value = new (googleService.value as GoogleMapsAPI).places.Autocomplete(container.value, {
        componentRestrictions: {
            country: 'us',
        },
        types: ['address']
    });

    (googleService.value as GoogleMapsAPI).event.addListener(autocomplete.value, 'place_changed', () => autocompleteCompleted())
};

const autocompleteCompleted = () => {
    const place = autocomplete.value.getPlace();
    updateFromPlace(place);
}

const updateFromPlace = async (place: any) => {
    if (!place.geometry) {
        // todo: handle error
        console.error('Please select an address from the list.');
        return;
    }

    const address = new Address();

    place.address_components.forEach(component => {
        switch (component.types[0]) {
            case "street_number":
                address.streetNumber = component.long_name;
                break;
            case "route":
                address.streetName = component.long_name;
                break;
            case "locality":
                address.city = component.long_name;
                break;
            case "sublocality_level_1":
                if(!address.city) {
                    address.city = component.long_name;
                }
                break;
            case "postal_code":
                address.zipCode = component.long_name;
                break;
            case "administrative_area_level_1":
                address.state = component.short_name;
                break;
            case "country":
                address.country = component.short_name;
                break;
        }
    });

    address.fullAddress = place.formatted_address;
    address.latitude = place.geometry.location.lat();
    address.longitude = place.geometry.location.lng();
    address.streetAddress = [address.streetNumber, address.streetName].filter((item: string) => item.length > 0).join(' ');

    payloadStore.flowPayload?.setBlockPayload(buildPayload(address));

    if (props.instance.autoProgress) {
        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance as BlockJson,
            actions: [],
        }

        await flowStore.update(
            payloadStore.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
            consumerStore.bearerToken,
            newEvent,
        ).catch(e => {
            errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
        });
    }
}

const buildPayload = (address: Address) => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        street_number: address.streetNumber,
        street_name: address.streetName,
        city: address.city || address.state,
        zip_code: address.zipCode,
        state: address.state,
        country: address.country,
        full_address: address.fullAddress,
        latitude: address.latitude,
        longitude: address.longitude,
        address_1: address.streetAddress
    },
    (props.instance as Block).id
);

//Build address object if available in initial data of the client
const buildAddressFromEngineInputs = (): Address|null => {
    const address = new Address(
        engineStore.getEngineInputByKey('street_number'),
        engineStore.getEngineInputByKey('street_name'),
        engineStore.getEngineInputByKey('city'),
        engineStore.getEngineInputByKey('zip_code'),
        engineStore.getEngineInputByKey('state'),
        engineStore.getEngineInputByKey('country') ?? 'US',
        engineStore.getEngineInputByKey('latitude'),
        engineStore.getEngineInputByKey('longitude'),
    );

    if (!address.streetNumber || !address.streetName || !address.city || !address.zipCode || !address.state || !address.latitude || !address.longitude)
        return null;

    address.streetAddress = engineStore.getEngineInputByKey('address_1') ?? `${address.streetNumber} ${address.streetName}`;
    address.fullAddress = engineStore.getEngineInputByKey('full_address') ?? `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipCode}, ${address.country}`;

    return address;
}

</script>