import {useEngineStore} from "../../../Stores/engines.ts";

export class ConversionEvents {
    protected engineStore = useEngineStore();
    fireEvents() {
        const consumerId = this.engineStore.getEngineOutputByEngineAndKey('lead', 'consumer_id');

        //@ts-ignore
        if (consumerId && window.dataLayer !== undefined && window.dataLayer !== null) {
            window.dataLayer.push({
                event: 'Purchase',
                eventID: consumerId
            });
        }
    }
}