<template>
    <template v-if="!(block.hideBlock && block.renderType === BlockRenderType.NoRender)">

        <RichTextRenderer
            v-if="block.type === 'rich-text'"
            :content="(block as RichText).content"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
            :mobile-font-size-override="mobileFontSizeOverride"
        />

        <EngineImageRenderer
                v-if="block.type === 'engine-image'"
                :instance="block"
                ref="blockElement"
                :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
                :style="style"
        />

        <ButtonRenderer
            v-else-if="block.type === 'button'"
            :content="(block as Button).content"
            :color="(block as Button).color"
            :gradient="(block as Button).gradient"
            :text-color="(block as Button).textColor"
            :icon-side="(block as Button).iconSide"
            :icon="(block as Button).icon"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
            :inner-styles="innerStyles"
            :actions="(block as Button).blockActions"
            :legacy-actions="(block as Button).actions ?? null"
        />

        <MultipleChoiceRenderer
            v-else-if="block.type === 'multiple-choice'"
            :options="(block as MultipleChoiceInput).options"
            :initial-value="(block as MultipleChoiceInput).value"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <RadioSelectRenderer
            v-else-if="block.type === 'radio-select'"
            :options="(block as RadioSelect).options"
            :initial-value="(block as RadioSelect).value"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <InputFieldRenderer
            v-else-if="block.type === 'input-field'"
            :label="(block as InputField).label"
            :placeholder="(block as InputField).placeholder"
            :type="(block as InputField).inputType"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
            :inner-styles="innerStyles"
        />

        <CheckboxRenderer
            v-else-if="block.type === 'checkbox'"
            :label="(block as Checkbox).label"
            :default-value="(block as Checkbox).defaultValue" :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
            :inner-styles="innerStyles"
        />

        <RangeInputRenderer
            v-else-if="block.type === 'range-input'"
            :label="(block as RangeInput).label"
            :min="(block as RangeInput).min" :max="(block as RangeInput).max"
            :step="(block as RangeInput).step" :default="(block as RangeInput).default"
            :primary-color="(block as RangeInput).primaryColor"
            :track-color="(block as RangeInput).trackColor" :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
            :additional-styles="style"
        />

        <ContainerRenderer
            v-else-if="block.type === 'container'"
            :blocks="(block as Container).blocks"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
            :nested-slide="nestedSlide"
        />

        <GridRenderer v-else-if="block.type === 'grid'" :blocks="(block as Grid).blocks"
                      :instance="block"
                      ref="blockElement"
                      :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
                      :style="style"
                      :nested-slide="nestedSlide"
        />

        <CarouselRenderer
            v-else-if="block.type === 'carousel'"
            :blocks="(block as Carousel).blocks" :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <SeparatorRenderer
            v-else-if="block.type === 'separator'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <PictureSelectRenderer
            v-else-if="block.type === 'picture-select'"
            :instance="block"
            :pictures="(block as PictureSelect).pictures"
            :initial-value="(block as PictureSelect).value"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <EnginePictureSelectRenderer
            v-else-if="block.type === 'engine-picture-select'"
            :instance="block"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />


        <ImageSelectRenderer
            v-else-if="block.type === 'image'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <MapMarkerRenderer
            v-else-if="block.type === 'map-marker'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <AddressFieldRenderer
            v-else-if="block.type === 'address-field'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <ProgressBarRenderer
            v-else-if="block.type === 'progressbar'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <IconRenderer
            v-else-if="block.type === 'icon'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <CountdownRenderer
            v-else-if="block.type === 'countdown'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />
        <PhoneVerificationRenderer
            v-else-if="block.type === 'phone-verification'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />
        <RendererRenderer
            v-else-if="block.type === 'renderer' && nestedSlide != null"
            :nested-slide="nestedSlide"
            :class="{[$attrs.class]: true}"
        />

        <AppointmentDateSelect
            v-else-if="block.type === 'appointment-date-select'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <AppointmentTimeSelect
            v-else-if="block.type === 'appointment-time-select'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <SlideErrorRenderer
            v-else-if="block.type === 'slide-error'"
            :instance="block"
            ref="blockElement"
            :class="{[$attrs.class]: true}"
        />

        <LoadingSpinnerRenderer
            v-else-if="block.type === 'loading-spinner'"
            :instance="block"
            ref="blockElement"
            :class="{[width]: true, [alignment]: true, [styleClasses]: true, [$attrs.class]: true}"
            :style="style"
        />

        <DropdownSelectRenderer
            v-else-if="block.type === 'dropdown-select'"
            :instance="block as DropdownSelect"
            ref="blockElement"
            :class="[width, alignment, styleClasses, $attrs.class]"
            :style="style"
        />

        <EngineSafeCallRenderer
            v-else-if="block.type === 'engine-safe-call'"
            :instance="block as EngineSafeCall"
            ref="blockElement"
            :class="[width, alignment, styleClasses, $attrs.class]"
            :style="style"
        />

        <OptInComboRendererBlur
            v-else-if="block.type === 'opt-in-combo' && block.rendererVersion === 'blur'"
            :instance="block as OptInCombo"
            ref="blockElement"
            :class="[width, alignment, styleClasses, $attrs.class]"
            :style="style"
        />

        <OptInComboRendererOpacity
            v-else-if="block.type === 'opt-in-combo' && block.rendererVersion === 'opacity'"
            :instance="block as OptInCombo"
            ref="blockElement"
            :class="[width, alignment, styleClasses, $attrs.class]"
            :style="style"
        />

      <OptInComboRendererPricesText
        v-else-if="block.type === 'opt-in-combo' && block.rendererVersion === 'prices-text'"
        :instance="block as OptInCombo"
        ref="blockElement"
        :class="[width, alignment, styleClasses, $attrs.class]"
        :style="style"
      />

      <OptInComboRendererDisclosure
        v-else-if="block.type === 'opt-in-combo' && block.rendererVersion === 'disclosure'"
        :instance="block as OptInCombo"
        ref="blockElement"
        :class="[width, alignment, styleClasses, $attrs.class]"
        :style="style"
      />

      <OptInComboRendererDisclosureText
        v-else-if="block.type === 'opt-in-combo' && block.rendererVersion === 'disclosure-text'"
        :instance="block as OptInCombo"
        ref="blockElement"
        :class="[width, alignment, styleClasses, $attrs.class]"
        :style="style"
      />

        <OptInComboRenderer
          v-else-if="block.type === 'opt-in-combo'"
          :instance="block as OptInCombo"
          ref="blockElement"
          :class="[width, alignment, styleClasses, $attrs.class]"
          :style="style"
        />

    </template>
</template>

<script setup lang="ts">
import Block, {
    BlockAlignment,
    BlockRenderType,
    BlockWidth
} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import MultipleChoiceInput from "@flow-builder/core/src/Blocks/Core/Inputs/MultipleChoiceInput.ts";
import ButtonRenderer from "../BlockRenderers/Buttons/ButtonRenderer.vue";
import MultipleChoiceRenderer from "../BlockRenderers/Inputs/MultipleChoiceRenderer.vue";
import RichTextRenderer from "../BlockRenderers/RichText/RichTextRenderer.vue";
import ContainerRenderer from "../BlockRenderers/Layouts/ContainerRenderer.vue";
import Container from "@flow-builder/core/src/Blocks/Core/Layout/Container.ts";
import InputFieldRenderer from "../BlockRenderers/Inputs/InputFieldRenderer.vue";
import InputField from "@flow-builder/core/src/Blocks/Core/Inputs/InputField.ts";
import CheckboxRenderer from "../BlockRenderers/Inputs/CheckboxRenderer.vue";
import Checkbox from "@flow-builder/core/src/Blocks/Core/Inputs/Checkbox.ts";
import RangeInputRenderer from "../BlockRenderers/Inputs/RangeInputRenderer.vue";
import RangeInput from "@flow-builder/core/src/Blocks/Core/Inputs/RangeInput.ts";
import {computed, ref, Ref} from "vue";
import SeparatorRenderer from "@flow-builder/core/src/Components/Renderer/BlockRenderers/Layouts/SeparatorRenderer.vue";
import PictureSelectRenderer from "../BlockRenderers/Inputs/PictureSelectRenderer.vue";
import PictureSelect from "@flow-builder/core/src/Blocks/Core/Inputs/PictureSelect.ts";
import ImageSelectRenderer from "@flow-builder/core/src/Components/Renderer/BlockRenderers/Inputs/ImageRenderer.vue";
import MapMarkerRenderer from "../BlockRenderers/Inputs/MapMarkerRenderer.vue";
import AddressFieldRenderer from "../BlockRenderers/Inputs/AddressFieldRenderer.vue";
import CarouselRenderer from "../BlockRenderers/Layouts/CarouselRenderer.vue";
import Carousel from "@flow-builder/core/src/Blocks/Core/Layout/Carousel.ts";
import ProgressBarRenderer from "@flow-builder/core/src/Components/Renderer/BlockRenderers/Display/ProgressBarRenderer.vue";
import IconRenderer from "@flow-builder/core/src/Components/Renderer/BlockRenderers/Display/IconRenderer.vue";
import Grid from "@flow-builder/core/src/Blocks/Core/Layout/Grid.ts";
import GridRenderer from "../BlockRenderers/Layouts/GridRenderer.vue";
import {useDebugStore} from "@flow-builder/core/src/Stores/debug.ts";
import EnginePictureSelectRenderer from "../BlockRenderers/Inputs/EnginePictureSelectRenderer.vue";
import AppointmentDateSelect from "../BlockRenderers/Inputs/AppointmentDateSelect/AppointmentDateSelect.vue";
import AppointmentTimeSelect from "../BlockRenderers/Inputs/AppointmentTimeSelect/AppointmentTimeSelect.vue";
import RichText from "@flow-builder/core/src/Blocks/Core/Text/RichText.ts";
import Button from "@flow-builder/core/src/Blocks/Core/Buttons/Button.ts";
import PhoneVerificationRenderer from "../BlockRenderers/Inputs/PhoneVerificationRenderer.vue";
import RendererRenderer from "../BlockRenderers/Layouts/RendererRenderer.vue";
import SlideErrorRenderer from "../BlockRenderers/Layouts/SlideErrorRenderer.vue";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import EngineImageRenderer from "../BlockRenderers/Display/EngineImageRenderer.vue";
import RadioSelect from "@flow-builder/core/src/Blocks/Core/Inputs/RadioSelect.ts";
import RadioSelectRenderer from "../BlockRenderers/Inputs/RadioSelectRenderer.vue";
import {useEngineStore} from "../../Stores/engines.ts";
import {useGradientClasses} from "@flow-builder/core/src/Components/Renderer/Composables/useGradientClasses.ts";
import {useMobilePaddingOverride} from "@flow-builder/core/src/Components/Renderer/Composables/useMobilePaddingOverride.ts";
import LoadingSpinnerRenderer from "../BlockRenderers/Display/LoadingSpinnerRenderer.vue";
import CountdownRenderer from "../BlockRenderers/Display/CountdownRenderer.vue";
import DropdownSelect from "@flow-builder/core/src/Blocks/Core/Inputs/DropdownSelect.ts";
import DropdownSelectRenderer from "../BlockRenderers/Inputs/DropdownSelectRenderer.vue";
import EngineSafeCall from "@flow-builder/core/src/Blocks/Core/Buttons/EngineSafeCall.ts";
import EngineSafeCallRenderer from "../BlockRenderers/Buttons/EngineSafeCallRenderer.vue";
import OptInComboRenderer from "../BlockRenderers/Buttons/OptInComboRenderer.vue";
import OptInCombo from "@flow-builder/core/src/Blocks/Core/Buttons/OptInCombo.ts";
import OptInComboRendererBlur from "../BlockRenderers/Buttons/OptInComboRenderer-blur.vue";
import OptInComboRendererOpacity from "../BlockRenderers/Buttons/OptInComboRenderer-opacity.vue";
import OptInComboRendererPricesText from "../BlockRenderers/Buttons/OptInComboRenderer-prices-text.vue";
import OptInComboRendererDisclosure from "../BlockRenderers/Buttons/OptInComboRenderer-disclosure.vue";
import OptInComboRendererDisclosureText from "../BlockRenderers/Buttons/OptInComboRenderer-disclosure-text.vue";

interface Props {
    block: Block,
    nestedSlide?: Slide | null,
}
const props = defineProps<Props>();

const engineStore = useEngineStore();
const debugStore = useDebugStore();
const blockElement: Ref<HTMLElement | null> = ref(null);
const debugElement: Ref<HTMLElement | null> = ref(null);

const highlight = computed(() => debugStore.debug && debugStore.highlightElement === (props.block as Block).id)

const width = computed(() => {
    if ((props.block as Block).ignoreAutomaticSpacing)
        return "";

    switch (Number((props.block as Block).width)) {
        case BlockWidth.XS:
            return "fb-max-w-xs fb-w-full";
        case BlockWidth.SM:
            return "fb-max-w-sm fb-w-full";
        case BlockWidth.MD:
            return "fb-max-w-md fb-w-full";
        case BlockWidth.LG:
            return "fb-max-w-lg fb-w-full";
        case BlockWidth.XL:
            return "fb-max-w-xl fb-w-full";
        case BlockWidth.XL_2:
            return "fb-max-w-2xl fb-w-full";
        case BlockWidth.FULL:
        default:
            return "fb-w-full";
    }
});

const alignment = computed(() => {
    if ((props.block as Block).ignoreAutomaticSpacing)
        return "";

    switch ((props.block as Block).blockAlignment) {
        case BlockAlignment.Left:
            return "fb-mr-auto";
        case BlockAlignment.Right:
            return "fb-ml-auto";
        case BlockAlignment.Center:
        default:
            return "fb-mx-auto";
    }
});

const isMobileViewPort = computed(() => window.innerWidth < 768);

const mobileFontSizeOverride = computed(() => {
    if (isMobileViewPort.value) {
        if ('mobileFontSizeOverride' in props.block && props.block.mobileFontSizeOverride) {
            return props.block.mobileFontSizeOverride;
        }
    }
    return null;
});

const style = computed((): Object => {
    const block: Block = props.block as Block;
    let styles = {};

    const blockPadding = useMobilePaddingOverride(
        { top: block.paddingTop, right: block.paddingRight, bottom: block.paddingBottom, left: block.paddingLeft},
        block.mobilePadding,
        isMobileViewPort.value
    );

    styles = {
        ...styles,
        ...blockPadding,
    };

    if (block.overwriteMargin)
        styles = {
            ...styles,
            marginTop: block.marginTop === 'auto' ? 'auto' : `${block.marginTop}px`,
            marginLeft: block.marginLeft === 'auto' ? 'auto' : `${block.marginLeft}px`,
            marginBottom: block.marginBottom === 'auto' ? 'auto' : `${block.marginBottom}px`,
            marginRight: block.marginRight === 'auto' ? 'auto' : `${block.marginRight}px`,
        };

    if (block.styles.borderRadius > 0)
        styles = {...styles, borderRadius: `${block.styles.borderRadius}px`}

    if (block.positioning.top !== null)
        styles = {...styles, top: block.positioning.top}
    if (block.positioning.right !== null)
        styles = {...styles, right: block.positioning.right}
    if (block.positioning.bottom !== null)
        styles = {...styles, bottom: block.positioning.bottom}
    if (block.positioning.left !== null)
        styles = {...styles, left: block.positioning.left}

    if (block.styles.textSize !== null)
        styles = {...styles, fontSize: block.styles.textSize}
    if (block.styles.fontBold)
        styles = {...styles, fontWeight: 'bold'}
    if (block.styles.fontItalic)
        styles = {...styles, fontStyle: 'italic'}

    if (block.styles.minHeight !== null)
        styles = {...styles, minHeight: block.styles.minHeight}
    if (block.styles.height !== null)
        styles = {...styles, height: block.styles.height}
    if (block.styles.maxHeight !== null)
        styles = {...styles, maxHeight: block.styles.maxHeight}

    if (block.styles.minWidth !== null)
        styles = {...styles, minWidth: block.styles.minWidth}
    if (block.styles.width !== null)
        styles = {...styles, width: block.styles.width}
    if (block.styles.maxWidth !== null)
        styles = {...styles, maxWidth: block.styles.maxWidth}

    let engineImageLoaded = false;
    if (block.styles.backgroundEngineImage !== null && block.styles.backgroundEngineName) {
        const url = fetchImageUrl(block.styles.backgroundEngineName, block.styles.backgroundEngineImage);
        if (url) {
            styles = {
                ...styles,
                backgroundImage: `url('${url}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }
            engineImageLoaded = true;
        }
    }
    if (!engineImageLoaded) {
        if (block.styles.backgroundImage !== null)
            styles = {...styles, backgroundImage: block.styles.backgroundImage}
        if (block.styles.backgroundSize !== null)
            styles = {...styles, backgroundSize: block.styles.backgroundSize}
        if (block.styles.backgroundPosition !== null)
            styles = {...styles, backgroundPosition: block.styles.backgroundPosition}
        if (block.styles.backgroundRepeat !== null)
            styles = {...styles, backgroundRepeat: block.styles.backgroundRepeat}
    }

    return styles;
});

const styleClasses = computed(() => {
    const block: Block = props.block as Block;
    const classes = [];

    if (block.positioning.position !== null)
        classes.push(`fb-${block.positioning.position}`)

    if (block.hideBlock && block.renderType === BlockRenderType.Hidden) {
        classes.push('fb-invisible');
    }

    if (block.styles.backgroundColor !== null)
        classes.push(/^gradient/i.test(block.styles.backgroundColor)
            ? useGradientClasses(block.styles.backgroundColor) : `fb-bg-sol-${block.styles.backgroundColor}`);
    if (block.styles.textColor !== null)
        classes.push(`fb-text-sol-${block.styles.textColor}`);
    if (block.styles.borderColor !== null && (block.styles.borderLeft || block.styles.borderRight || block.styles.borderTop || block.styles.borderBottom))
        classes.push(`fb-border-sol-${block.styles.borderColor}`);
    if (block.styles.borderTop)
        classes.push(`fb-border-t`);
    if (block.styles.borderLeft)
        classes.push(`fb-border-l`);
    if (block.styles.borderRight)
        classes.push(`fb-border-r`);
    if (block.styles.borderBottom)
        classes.push(`fb-border-b`);
    if (block.styles.shadow)
        classes.push(`fb-shadow-sm`);

    if (block.hideOnMobile)
        classes.push('fb-hidden sm:fb-block');
    else if (block.hideOnDesktop)
        classes.push('fb-block sm:fb-hidden');

    return classes.join(' ');
});

const innerStyles = computed(() => {
    const block: Block = props.block as Block;
    let styles = {};

    if (block.hasInnerPadding)
        styles = {
            ...styles,
            paddingTop: `${block.innerPadding.top}px`,
            paddingLeft: `${block.innerPadding.left}px`,
            paddingBottom: `${block.innerPadding.bottom}px`,
            paddingRight: `${block.innerPadding.right}px`
        }

    return styles;
});

const fetchImageUrl = (engineName: string, engineKey: string): string|null => {
    return engineStore.outputs[engineName]?.[engineKey]?.value ?? null;
}

</script>
