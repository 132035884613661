import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {SelectedPicture} from "@flow-builder/core/src/Blocks/Core/Inputs/PictureSelect.ts";

/**
 * Load and decode an Image by URL
 *
 * @param imageElement
 * @param maxWait
 */
const awaitImageLoad = (imageElement: HTMLImageElement, maxWait: number): Promise<boolean> => {
    return new Promise(res => {
        setTimeout(() => {
            res(false);
        }, maxWait);
        if (imageElement !== null) {
            if (imageElement.complete) res(true);
            else {
                imageElement.decode().then(() => {
                    res(true);
                }).catch(e => {
                    console.error(e);
                    res(false);
                });
            }
        }
    });
}

/**
 * Preloads an image to DOM to allow a better fade-in on slides
 * maximum image load timeout should be the same or greater than the maximumImagePreloadTime in flow.ts
 *
 * @param imageUrl
 * @param timeout
 */
export const preloadImage = async (imageUrl: string, timeout = 2000) => {
    const preloadElement = document.querySelector("#preloader");
    if (preloadElement) {
        const newImage = document.createElement('img');
        newImage.src = imageUrl;
        newImage.style.position = 'absolute';
        newImage.style.maxHeight = '0px';

        const result = await awaitImageLoad(newImage, timeout);
        if (result) preloadElement.append(newImage);

        return result;
    }
}

/**
 * Check a slide for image URLs which have not yet been preloaded in the DOM
 * Pass in the Template slide only when relevant (ie ScreenToTemplate transitions);
 *
 * @param slide
 * @param loadedUrls
 * @param template
 */
export const checkSlideForImages = (slide: Slide, loadedUrls: string[], template?: Slide) => {
    const output: string[] = [];
    const slideBackground = slide.slideStyles?.backgroundImage ?? null;
    if (slideBackground) {
        const cleanUrl = validateUrl(slideBackground);
        if (cleanUrl && !loadedUrls.includes(cleanUrl)) output.push(cleanUrl);
    }

    const processBlocks = (blocks: BlockJson[]) => {
        blocks.forEach(block => {
            if (block.type === 'picture-select' && 'pictures' in block) {
                (block.pictures as SelectedPicture[]).forEach(picture => {
                    const validUrl = validateUrl(picture.content);
                    if (validUrl && !loadedUrls.includes(validUrl)) output.push(validUrl);
                });
            }
            else {
                const url: string = (block.type === 'image' && 'content' in block && block.content)
                    ? block.content as string
                    : block.styles?.backgroundImage as string;
                const validUrl = validateUrl(url);
                if (validUrl && !loadedUrls.includes(validUrl)) {
                    output.push(validUrl);
                }
            }

            if (block.blocks?.length) {
                processBlocks(block.blocks);
            }
        });
    }
    //@ts-ignore
    processBlocks(slide.hierarchy);

    if (template) {
        //@ts-ignore
        processBlocks(template.hierarchy);
    }

    return output;
}

/**
 * Clean up the url('') surround on a CSS image background link
 * @param url
 */
const cleanImageUrl = (url: string) => {
    return /^url\(/.test(url)
        ? url.replace(/^url\(['"`]/i, '').replace(/['"`]\)$/, '')
        : url;
}

/**
 * Ensure malformed url's don't get passed to preloader
 * @param urlInput
 */
const validateUrl = (urlInput: string | null): string | null => {
    if (typeof(urlInput) !== 'string') return null;
    const cleanUrl = cleanImageUrl(urlInput);
    if (cleanUrl && /^http/.test(cleanUrl)) {
        try {
            const newUrl = new URL(cleanUrl);
            return newUrl.href;
        }
        catch(_e) {}
    }

    return null;
}
