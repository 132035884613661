<template>
    <div class="fb-gap-4"
         :class="{
            'fb-grid fb-grid-cols-1': instance?.direction === ContainerDirection.Vertical,
            'fb-flex fb-flex-row': instance?.direction === ContainerDirection.Horizontal,
            [containerClasses]: true
         }"
    >
        <BlockRenderer
            v-for="block in blocks"
            :key="block.id"
            :block="block"
            :class="(instance as Container)?.flexRatios[block.id] ? Object.values(BlockFlexSizes)[(instance as Container)?.flexRatios[block.id]] : 'fb-flex-1'"
            :nested-slide="nestedSlide"
        />
    </div>
</template>

<script setup lang="ts">
import Container, { BlockFlexSizes, ContainerAlignment, ContainerDirection } from "@flow-builder/core/src/Blocks/Core/Layout/Container.ts";
import BlockRenderer from "../../WebRenderer/BlockRenderer.vue";
import {computed} from "vue";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";

interface Props {
    blocks: BlockJson[],
    instance: BlockJson,
    nestedSlide?: Slide | Object | null,
}
const props = defineProps<Props>();

const containerClasses = computed(() => {
    const classes = [];

    classes.push(`fb-${(props.instance as Container).align}`);
    classes.push(`fb-${(props.instance as Container).justify}`);

    return classes.join(' ');
})

</script>