<template>
    <div>
        <div class="fb-grid fb-grid-cols-3 fb-items-center fb-pb-2">
            <label class="fb-col-start-3 fb-row-start-1 fb-justify-self-end fb-font-display fb-text-base fb-text-gray-500 fb-text-lg fb-font-medium" v-if="label && label.trim().length > 0"
                   :style="labelPositionStyle"
            >
                {{ label }}
            </label>
            <p :class="`fb-pb-0 fb-row-start-1 fb-font-bold fb-font-display fb-justify-self-center fb-text-xl fb-text-sol-${primaryColor ?? 'primary'}`"
               :style="valuePositionStyle"
            >
                {{ (instance as RangeInput).prefix }}{{ component }}{{ (instance as RangeInput).suffix }}
            </p>
        </div>

        <div class="fb-flex fb-items-center fb-mt-6">
            <div v-if="instance.showMinMax">
                <p :class="`fb-pb-0 fb-font-bold fb-font-display fb-text-lg fb-text-sol-${primaryColor ?? 'primary'}`">
                    {{ (instance as RangeInput).prefix }}{{ instance.min }}{{ (instance as RangeInput).suffix }}
                </p>
            </div>
            <div class="fb-w-full fb-slider fb-px-3 fb-flex fb-items-center">
                <input type="range"
                       v-model="component"
                       :min="min"
                       :max="max"
                       :step="step"
                       aria-orientation="horizontal"
                       :aria-valuemin="min"
                       :aria-valuemax="max"
                       :style="{'background-size': `${backgroundSize}% 100%`}"
                       class="fb-w-full fb-inline-block fb-align-middle fb-appearance-none fb-border-none fb-rounded-full fb-bg-no-repeat fb-h-2 fb-bg-gradient-to-r"
                       :class="{[thumbClasses]: true, [trackClasses]: true}"
                >
            </div>
            <div v-if="instance.showMinMax">
                <p :class="`fb-pb-0 fb-whitespace-nowrap fb-font-bold fb-font-display fb-text-lg fb-text-sol-${primaryColor ?? 'primary'}`">
                    {{ (instance as RangeInput).prefix }}{{ instance.max }}{{ (instance as RangeInput).suffix }} +
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, Ref, watch} from "vue";
import RangeInput from "@flow-builder/core/src//Blocks/Core/Inputs/RangeInput.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import Input from "@flow-builder/core/src/Blocks/Core/Inputs/Input.ts";
import Block, {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {onBeforeMount} from "vue";
import {usePayload} from "../../../Composables/payload.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";

interface Props {
    label: string | null,
    min: number | null,
    max: number | null,
    step: number | null,
    prefix?: string | null,
    suffix?: string | null,
    instance: RangeInput,
    default: number | null,
    primaryColor: string | null,
    trackColor: string | null,
    additionalStyles: {[key: string]: string},
}
const props = withDefaults(defineProps<Props>(), {
    label: "",
    default: 0,
    primaryColor: 'primary',
    trackColor: 'light-gray',
});

const component: Ref<number> = ref(0);
const updateDebounce: Ref<null|Number> = ref(null);

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

const initialise = () => {
    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        (props.instance as RangeInput).id
    );

    component.value = payload?.payload ? payload.payload[(props.instance as Input).backendIdentifier] : props.default ?? props.min ?? 0;
}

onMounted(() => initialise());

const backgroundSize = computed(() => (component.value - props.min) * 100 / (props.max - props.min));

const thumbClasses = computed(() => {
    switch(props.primaryColor ?? "primary") {
        // Need to do a switch so tailwind compiles these classes correctly.
        case 'primary':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-primary [&::-moz-range-thumb]:fb-bg-sol-primary [&::-ms-thumb]:fb-bg-sol-primary';
        case 'secondary':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-secondary [&::-moz-range-thumb]:fb-bg-sol-secondary [&::-ms-thumb]:fb-bg-sol-secondary';
        case 'white':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-white [&::-moz-range-thumb]:fb-bg-sol-white [&::-ms-thumb]:fb-bg-sol-white';
        case 'black':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-black [&::-moz-range-thumb]:fb-bg-sol-black [&::-ms-thumb]:fb-bg-sol-black';
        case 'off-white':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-off-white [&::-moz-range-thumb]:fb-bg-sol-off-white [&::-ms-thumb]:fb-bg-sol-off-white';
        case 'light-gray':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-light-gray [&::-moz-range-thumb]:fb-bg-sol-light-gray [&::-ms-thumb]:fb-bg-sol-light-gray';
        case 'gray':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-gray [&::-moz-range-thumb]:fb-bg-sol-gray [&::-ms-thumb]:fb-bg-sol-gray';
        case 'dark-gray':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-dark-gray [&::-moz-range-thumb]:fb-bg-sol-dark-gray [&::-ms-thumb]:fb-bg-sol-dark-gray';
        case 'custom-1':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-custom-1 [&::-moz-range-thumb]:fb-bg-sol-custom-1 [&::-ms-thumb]:fb-bg-sol-custom-1';
        case 'custom-2':
            return '[&::-webkit-slider-thumb]:fb-bg-sol-custom-2 [&::-moz-range-thumb]:fb-bg-sol-custom-2 [&::-ms-thumb]:fb-bg-sol-custom-2';
    }

    return '[&::-webkit-slider-thumb]:fb-bg-sol-primary [&::-moz-range-thumb]:fb-bg-sol-primary [&::-ms-thumb]:fb-bg-sol-primary';
});

const trackClasses = computed(() => `fb-bg-sol-${props.trackColor ?? 'light-gray'} fb-from-sol-${props.primaryColor ?? 'primary'}-light fb-to-sol-${props.primaryColor ?? 'primary'}-light`);

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [(props.instance as Input).backendIdentifier]: component.value
    },
    (props.instance as Block).id
);

watch(() => component.value, () => {
    payloadStore.flowPayload?.setBlockPayload(buildPayload())

    if((props.instance as Input).updateOnChange || (props.instance as Input).refreshOnChange) {
        if(updateDebounce.value !== null)
            clearTimeout(updateDebounce.value as Number)

        let slideAction = SlideActionType.None;

        if ((props.instance as Input).refreshOnChange) {
          slideAction = SlideActionType.RerenderSlide;
        }

        const newEvent: SlideUpdateEvent = {
            type: slideAction,
            trigger: props.instance as BlockJson,
        }

        updateDebounce.value = setTimeout(() => {
            flowStore.update(
                payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
                consumerStore.bearerToken,
                newEvent
            );

            updateDebounce.value = null;
        }, 250) as Number;
    }

});

const positionStyles = (position: number): {[key: string]: string|number} => {
    return position === 1
        ? { "justify-self": "start", "grid-column-start": 1 }
        : position === 2
            ? { "justify-self": "center", "grid-column-start": 2 }
            : { "justify-self": "end", "grid-column-start": 3 };
}

const labelPositionStyle = computed(() => positionStyles(props.instance?.labelPosition ?? 1));

const valuePositionStyle = computed(() => {
    const styles = positionStyles(props.instance?.valuePosition ?? 3);
    if (props.additionalStyles.fontSize)
        styles.fontSize = props.additionalStyles.fontSize;
    if (props.additionalStyles.fontBold)
        styles.fontBold = props.additionalStyles.fontBold;
    if (props.additionalStyles.fontItalic)
        styles.fontItalic = props.additionalStyles.fontItalic;

    return styles;
});

</script>

<style scoped>
.fb-slider input[type="range"]::-webkit-slider-thumb {
    @apply fb-appearance-none fb-h-7 fb-w-7 fb-rounded-full fb-cursor-pointer fb-transition-all fb-duration-300 fb-border-none;
}

.fb-slider input[type="range"]::-moz-range-thumb {
    @apply fb-appearance-none fb-h-7 fb-w-7 fb-rounded-full fb-cursor-pointer fb-transition-all fb-duration-300 fb-border-none;
}

.fb-slider input[type="range"]::-ms-thumb {
    @apply fb-appearance-none fb-h-7 fb-w-7 fb-rounded-full fb-cursor-pointer fb-transition-all fb-duration-300 fb-border-none;
}

.fb-slider input[type="range"]::-webkit-slider-runnable-track {
    @apply fb-bg-transparent fb-shadow-none fb-border-none fb-appearance-none;
}

.fb-slider input[type="range"]::-moz-range-track {
    @apply fb-bg-transparent fb-shadow-none fb-border-none fb-appearance-none;
}

.fb-slider input[type="range"]::-ms-track {
    @apply fb-bg-transparent fb-shadow-none fb-border-none fb-appearance-none;
}
</style>
