<template>
    <div class="fb-w-full fb-relative"
         :class="[saving && 'fb-pointer-events-none fb-opacity-40']"
         ref="dropdownReference"
    >
        <div class="fb-text-gray-500 fb-text-sm fb-font-bold fb-mb-1" v-if="instance.label">
            {{ instance.label }}
        </div>
        <div @click="toggleDropdown"
             :class="[dropdownActive ? 'fb-border-sol-gray fb-shadow-lg' : 'fb-border-sol-light-gray']"
             class="fb-relative fb-bg-white fb-cursor-pointer fb-text-sm fb-font-medium fb-transition fb-duration-100 fb-w-full fb-border fb-flex fb-justify-between fb-items-center fb-rounded fb-px-3 fb-h-10">
            <p class="leading-5">{{ valueDisplay }}</p>
            <svg
                :class="[dropdownActive && 'fb-rotate-180']"
                class="fb-text-sol-primary fb-fill-current fb-w-6 fb-transform fb-transition-all fb-duration-200"
                width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2071 0.792893C12.5976 1.18342 12.5976 1.81658 12.2071 2.20711L7.20711 7.20711C6.81658 7.59763 6.18342 7.59763 5.79289 7.20711L0.792893 2.20711C0.402368 1.81658 0.402369 1.18342 0.792893 0.792893C1.18342 0.402368 1.81658 0.402368 2.20711 0.792893L6.5 5.08579L10.7929 0.792893C11.1834 0.402369 11.8166 0.402369 12.2071 0.792893Z"/>
            </svg>

            <div v-show="dropdownActive"
                 class="fb-max-h-40 fb-absolute fb-left-0 fb-top-10 fb-divide-y fb-divide-inherit fb-z-50 fb-overflow-y-auto fb-rounded fb-w-full fb-border fb-shadow-md fb-border-sol-gray fb-bg-white"
                 :style="{maxHeight: style?.maxHeight ?? ''}"
            >
                <p
                    v-for="(option, index) in instance.options"
                    class="hover:fb-bg-gray-100 fb-py-3 fb-px-3 fb-text-sm fb-font-medium fb-transition fb-duration-200"
                    :class="[multiSelectOptions.includes(option) && 'fb-text-sol-primary']"
                    @click.stop="() => selectOption(option)"
                    :key="option.value || index">
                    {{ option.displayName }}
                </p>
            </div>

        </div>
        <div v-if="dropdownActive" @click="toggleDropdown" class="fixed inset-0 z-10"></div>
    </div>
</template>

<script setup lang="ts">

import {computed, CSSProperties, onBeforeMount, onMounted, onUnmounted, ref, Ref} from "vue";
import DropdownSelect, {DropdownSelectOption} from "@flow-builder/core/src/Blocks/Core/Inputs/DropdownSelect.ts";
import {usePayload} from "../../../Composables/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {BaseAction, SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useErrorStore} from "../../../Stores/errors.ts";

interface Props {
    instance: DropdownSelect,
    style: CSSProperties,
}
const props = defineProps<Props>();

const dropdownActive: Ref<boolean> = ref(false);
const dropdownReference: Ref<HTMLElement|null> = ref(null);

const selectedOption: Ref<DropdownSelectOption|null> = ref(null);
const multiSelectOptions: Ref<DropdownSelectOption[]> = ref([]);
const saving: Ref<boolean> = ref(false)

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

onMounted(() => initialize());
onUnmounted(() => document.removeEventListener('click', hide));

const initialize = () => {
    document.addEventListener('click', hide);
    if (props.instance.value) {
        const targetOption = props.instance.options.find(option => option.value === props.instance.value);
        if (targetOption) {
            if (props.instance.multiSelect) multiSelectOptions.value.push(targetOption);
            else selectedOption.value = targetOption;
            payloadStore.flowPayload?.setBlockPayload(buildPayload());
        }
    }
}

const hide = (ev: Event) => {
    if (dropdownReference.value && !ev.composedPath().includes(dropdownReference.value)) {
        dropdownActive.value = false;
    }
}

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',{
        [props.instance.backendIdentifier]: (props.instance.autoProgress || !props.instance.multiSelect) ? selectedOption.value?.value : multiSelectOptions.value.map(option => option.value)
    },
    props.instance.id
);

const valueDisplay = computed(() => {
    return props.instance.multiSelect
        ? multiSelectOptions.value.length ? multiSelectOptions.value.map(option => option.displayName).join(', ') : props.instance.placeholder
        : selectedOption.value?.displayName || props.instance.placeholder;
});

const toggleDropdown = () => {
    dropdownActive.value = !dropdownActive.value;
}

const selectOption = async (option: DropdownSelectOption) => {
    if (saving.value) return;

    if (props.instance.multiSelect) {
        const index = multiSelectOptions.value.findIndex(selectedOption => selectedOption === option);
        if (index >= 0) {
            multiSelectOptions.value.splice(index, 1);
        }
        else {
            multiSelectOptions.value.push(option);
        }
    }
    else {
        selectedOption.value = option;
        dropdownActive.value = false;
    }
    payloadStore.flowPayload?.setBlockPayload(buildPayload());

    if (props.instance.autoProgress) {
        saving.value = true;

        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance,
            actions: getOptionActions(),
        }

        await flowStore.update(
            payloadStore.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? "") ?? {},
            consumerStore.bearerToken,
            newEvent,
        ).catch(e => errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? ''));

    }

    setTimeout(() => saving.value = false, 400);
}

const getOptionActions = (): BaseAction[] => {
    if (props.instance.multiSelect) {
        return multiSelectOptions.value.reduce((output, option) => {
            return [...output, ...option.blockActions];
        }, [] as BaseAction[]);
    }
    else return selectedOption.value?.blockActions ?? [];
}

</script>