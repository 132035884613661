import * as rrweb from "rrweb";
import * as Sentry from "@sentry/browser";
import axios, { AxiosError } from "axios";
import { getData as getDataFromGeographicLocation } from "./GeographicLocation";
import FlowApiService from "./Flow/FlowApiService";

type Context = {
    event: undefined | object;
    events: undefined | Array<object>;
};

export class WatchdogV2 {
    events: any[] = [];
    ignoredEvents: any[] = [];
    site: URL;
    token: string;
    flowApiService?: FlowApiService;
    consumerToken?: string;
    fetchingVideoId: boolean = false;
    fetchVideoIdAttemptCount: number = 0;
    certificateSent: boolean = false;
    eventsSent: boolean = false;
    videoId: string | null = null;
    sendingInitialEvents: boolean = false;
    sendingCertificate: boolean = false;

    public constructor(site: string | null, token: string | null, flowApiService?: FlowApiService, consumerToken?: string) {
        if (!site) {
            throw new Error(`Site must not be an empty value. ${site} passed.`);
        }

        if (!token) {
            throw new Error(`Token must not be an empty value. ${token} passed.`);
        }

        try {
            this.site = new URL(site);
        } catch (error: any) {
            throw new Error(error.message);
        }

        this.token = token;

        if (flowApiService) {
            this.flowApiService = flowApiService;
        }

        if (consumerToken) {
            this.consumerToken = consumerToken;
        }
    }

    public init() {
        this.startRecording();

        this.pollForVideoId();
    }

    /* v8 ignore next 20 */
    startRecording() {
        let self = this;

        return rrweb.record({
            emit(event: any) {
                self.processEvent(event);
            },
            sampling: {
                mousemove: false,
                mouseInteraction: true,
                scroll: 150,
                media: 800,
                input: "all",
            },
            slimDOMOptions: {
                comment: true,
            },
        });
    }

    requestNewVideoForConsumer() {
        if (!(this.flowApiService && this.consumerToken)) {
            return;
        }

        this.removeVideoId();

        return this.flowApiService.createConsumerVideo(this.consumerToken).then(() => {
            this.pollForVideoId();
        });
    }

    /* v8 ignore next 10 */
    pollForVideoId() {
        const interval = setInterval(async () => {
            try {
                await this.fetchVideoId();
            } catch (error: any) {
                clearInterval(interval);
            }
        }, 3000);
    }

    fetchVideoId() {
        if (!this.flowApiService) {
            throw Error("Flow API Service not set.");
        }

        if (!this.consumerToken) {
            throw Error("Consumer Token not set.");
        }

        if (this.fetchingVideoId) {
            throw Error("Already fetching video id from consumer.");
        }

        if (this.getVideoId()) {
            if (!this.eventsSent && !this.sendingInitialEvents) {
                this.logError(
                    new Error("Events were not sent to the server even though there is a video id. No events were currently being sent at the time.")
                );
            }

            if (!this.certificateSent && !this.sendingCertificate) {
                this.logError(
                    new Error("Certificate was not sent to the server even though there is a video id. No certificate was currently being sent at the time.")
                );
            }

            throw Error("Video id already exists.");
        }

        this.fetchingVideoId = true;

        return this.flowApiService
            .getConsumerVideo(this.consumerToken)
            .then((response) => {
                this.setVideoId(response.data.data.video);

                this.sendingInitialEvents = true;

                this.updateVideo("patch", this.getUrl(`videos/${this.getVideoId()}`), {
                    events: this.events,
                })
                    .then(() => {
                        this.eventsSent = true;
                    })
                    .finally(() => {
                        this.sendingInitialEvents = false;
                    });

                this.createCertificate();
            })
            .catch((error) => {
                this.fetchVideoIdAttemptCount += 1;

                if (this.fetchVideoIdAttemptCount >= 3) {
                    const status = this.getStatusFromError(error);

                    const message = this.buildStatusErrorMessage(
                        `Unable to retrieve video id from Flow Engines. Recording failed. ${this.fetchVideoIdAttemptCount} attempts made. Bearer Token: ${this.consumerToken}`,
                        error,
                        status
                    );

                    this.logError(error, {
                        status,
                        message,
                    });

                    const attemptCount = this.fetchVideoIdAttemptCount;

                    this.fetchVideoIdAttemptCount = 0;

                    throw Error(`Failed ${attemptCount} times to fetch video id from consumer.`);
                }
            })
            .finally(() => {
                this.fetchingVideoId = false;
            });
    }

    createCertificate() {
        if (this.getVideoId() && !this.sendingCertificate) {
            this.sendingCertificate = true;

            return axios
                .post(
                    this.getUrl(`videos/${this.getVideoId()}/certificates`),
                    {
                        url: window.location.href,
                        city: getDataFromGeographicLocation("locality"),
                        state: getDataFromGeographicLocation("administrative_area_level_1"),
                        country: getDataFromGeographicLocation("country"),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                )
                .then(() => {
                    this.certificateSent = true;
                })
                .catch((error) => {
                    const status = this.getStatusFromError(error);

                    const message = this.buildStatusErrorMessage(`Could not create certificate (${this.getVideoId()}).`, error, status);

                    this.logError(error, {
                        status,
                        message,
                    });
                })
                .finally(() => {
                    this.sendingCertificate = false;
                });
        }
    }

    /**
     * @param event
     */
    async processEvent(event: any) {
        if (this.isNotValidEvent(event)) {
            this.logError(new Error("Event is not valid"), {
                event: JSON.stringify(event),
            });

            return;
        }

        if (this.shouldIgnoreEvent(event)) {
            this.ignoredEvents.push(event);
            return;
        }

        this.events.push(event);

        if (this.getVideoId()) {
            return this.updateVideo("post", this.getUrl(`videos/${this.getVideoId()}/events`), {
                event,
            });
        }
    }

    shouldIgnoreEvent(event: any) {
        return (
            event?.type == 3 &&
            Array.isArray(event?.data?.attributes) &&
            event?.data?.attributes.length > 0 &&
            typeof event?.data?.attributes[0]?.attributes?.style == "object" &&
            event?.data?.attributes[0]?.attributes?.style.hasOwnProperty("opacity")
        );
    }

    async updateVideo(method: string, url: string, data: Context | any) {
        if (this.isNotValidDataObject(data)) {
            return;
        }

        return axios
            .request({
                method,
                url,
                data,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            })
            .catch((error) => {
                const status = this.getStatusFromError(error);

                if ([406, 404].some((code) => code === status)) {
                    if (data.hasOwnProperty("events")) {
                        this.events = data.events;
                    }

                    if (data.hasOwnProperty("event")) {
                        this.events.push(data.event);
                    }

                    this.requestNewVideoForConsumer();

                    return;
                }

                const message = this.buildStatusErrorMessage(`Could not update video (${this.getVideoId()}).`, error, status);

                const context: any = {
                    event: data.event ? JSON.stringify(data.event) : undefined,
                    events: data.events ? JSON.stringify(data.events) : undefined,
                    status,
                    message,
                };

                this.logError(error, context);
            });
    }

    public getUrl(path: string) {
        return this.site.href + `api/${path}`;
    }

    getVideoId() {
        return this.videoId;
    }

    setVideoId(id: string) {
        this.videoId = id;
    }

    removeVideoId() {
        this.videoId = null;
    }

    isValidEvent(event: any) {
        return !!event?.data && !!event?.timestamp && (event?.type != 0 ? !!event?.type : true);
    }

    isNotValidEvent(event: any) {
        return !this.isValidEvent(event);
    }

    hasEvents(data: any): data is Context {
        return data.hasOwnProperty("events") && typeof data.events === "object";
    }

    hasEvent(data: any): data is Context {
        return data.hasOwnProperty("event") && typeof data.event === "object";
    }

    isValidDataObject(data: any): data is Context {
        const hasValidEvents = this.hasEvents(data) && !!data.events?.filter((event) => this.isValidEvent(event))?.length;
        const hasValidEvent = this.hasEvent(data) && this.isValidEvent(data.event);

        return hasValidEvents || hasValidEvent;
    }

    isNotValidDataObject(data: any) {
        return !this.isValidDataObject(data);
    }

    logError(exception: any, context: any | object | null = null) {
        if (context) {
            Sentry.setContext("watchdog", context);
        }

        if (exception?.response) {
            Sentry.captureException(exception, {
                tags: {
                    area: "Watchdog",
                },
                extra: {
                    config: exception.config,
                    response: exception.response,
                    responseStatus: exception.response?.status,
                    responseHeaders: exception.response?.headers,
                    responseData: exception.response?.data,
                    errorJSON: exception?.toJSON(),
                },
            });
        } else if (exception?.request) {
            Sentry.captureException(exception, {
                tags: {
                    area: "Watchdog",
                },
                extra: {
                    request: exception.request,
                    errorJSON: exception?.toJSON(),
                },
            });
        } else {
            Sentry.captureException(exception, {
                tags: {
                    area: "Watchdog",
                },
            });
        }
    }

    private getStatusFromError(error: any) {
        return error?.response?.status ?? error?.status;
    }

    private buildStatusErrorMessage(customMessage: string, error: any, status: number | any) {
        let message = "";

        if (status) {
            message += `Failed with status: ${status}.`;
        }

        message += " " + (error?.response?.data?.message ?? error?.message ?? error);

        if (customMessage) {
            message = `${customMessage} ${message}`;
        }

        return message;
    }
}
