<template>
    <div class="fb-grid" :class="`fb-grid-cols-${Object.keys(availableTimes).length}`">
        <div v-for="(times, period) in availableTimes" :key="period" class="fb-flex fb-flex-col fb-items-center fb-gap-3">
            <p class="fb-font-medium fb-text-sm fb-capitalize fb-text-[#656565]">{{ period }}</p>
            <time-card v-for="(time, idx) in times" :key="idx" :time="time" @click="handleTimeClick"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType} from "vue";
import AppointmentTimeSelect from "@flow-builder/core/src/Blocks/Core/Inputs/AppointmentTimeSelect.ts";
import TimeCard from './TimeCard.vue'
import BlockPayload from "../../../../Payload/BlockPayload.ts";
import {ErrorResponse} from "../../../../Errors/SlideErrors.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../../Stores/flow.ts";
import {usePayloadStore} from "../../../../Stores/payload.ts";
import {useConsumerStore} from "../../../../Stores/consumer.ts";
import {useErrorStore} from "../../../../Stores/errors.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";

const props = defineProps({
    instance: {
        type: Object as PropType<AppointmentTimeSelect>,
        required: true
    },
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

const buildPayload = (time) => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [props.instance.backendIdentifier]: time
    },
    props.instance.id
);

const handleTimeClick = (time) => {
    payloadStore.flowPayload?.setBlockPayload(buildPayload(time));

    errorStore.slideErrors.resetSlideError(flowStore.sliderService.currentSlide?.id ?? '');

    const newEvent: SlideUpdateEvent = {
        type: SlideActionType.NextSlide,
        trigger: props.instance as BlockJson,
    }

    flowStore.update(
        payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
        consumerStore.bearerToken,
        newEvent,
    ).catch(e => errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? ''));
}

const availableTimes = computed(() => {
    const startDate = new Date();
    startDate.setHours(0,0,0,0);

    return Object.entries(getTimes(startDate, props.instance.startAvailableHour, props.instance.endAvailableHour))
        .filter(([_, hours]) => hours.length > 0)
        .reduce((prev, [period, hours]) => Object.assign(prev, {[period]: hours}), {});
})
const compareDates = (date1: Date, date2: Date) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return date1.getTime() === date2.getTime();
};

const getTimes = (
    startDate: Date,
    startAvailableHour: number,
    endAvailableHour: number
) => {
    const availableHours = {
        morning: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        afternoon: [12, 13, 14, 15],
        evening: [16, 17, 18, 19, 20, 21, 22, 23],
    };

    // If is not today just return all available hours baby
    if (!compareDates(startDate, new Date())) return availableHours;

    const hour = startDate.getHours();

    return Object.entries(availableHours).reduce((prev, [period, hours]) => {
        return Object.assign(prev, {
            [period]: hours.filter(
                (h) => h >= startAvailableHour && h <= endAvailableHour && h > hour + 1
            ),
        });
    }, {});
};
</script>