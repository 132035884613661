<template>
    <div :class="layoutClasses">
        <div v-for="option in blockOptions" :key="option.id"
             class="fb-flex fb-py-1 fb-cursor-pointer fb-group fb-items-center"
             :class="{
                'fb-flex-1': instance.layoutType === MultipleChoiceLayoutType.Horizontally,
                'fb-invisible': option.hideItem && option.renderType === BlockRenderType.Hidden,
             }"
             @click="handleClick(option)"
        >
            <div class="fb-rounded-full fb-border-2 fb-w-[1rem] fb-h-[1rem] fb-mr-3 fb-flex fb-items-center fb-justify-center fb-border-blue-500"
                 :class="radioBorderColor"
            >
                <div
                    class="fb-rounded-full fb-border fb-bg-blue-500 fb-w-[0.6rem] fb-h-[0.6rem]"
                    :class="radioBackgroundColor"
                    v-if="values.indexOf(option.value) !== -1"
                ></div>
            </div>
            <div>
                {{ option.displayName }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, ref} from "vue";
import {
    MultipleChoiceInputOption,
    MultipleChoiceLayoutType
} from "@flow-builder/core/src/Blocks/Core/Inputs/MultipleChoiceInput.ts";
import RadioSelect from "@flow-builder/core/src/Blocks/Core/Inputs/RadioSelect.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import Input from "@flow-builder/core/src/Blocks/Core/Inputs/Input.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useGridColumnOverride} from "@flow-builder/core/src/Components/Renderer/Composables/useGridColumnOverride.ts";
import {usePayload} from "../../../Composables/payload.ts";
import {Ref} from "vue";
import Block, {BlockJson, BlockRenderType} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";

interface Props {
    options: MultipleChoiceInputOption[],
    instance: RadioSelect,
    initialValue: any,
}
const props = defineProps<Props>();

const GRID_GAP_MAPPINGS = ['fb-gap-1', 'fb-gap-2', 'fb-gap-3', 'fb-gap-4', 'fb-gap-5', 'fb-gap-6', 'fb-gap-7', 'fb-gap-8', 'fb-gap-9', 'fb-gap-10', 'fb-gap-11', 'fb-gap-12'];

const values = ref([]);
const validValues = [];
const saving: Ref<boolean> = ref(false);

const blockOptions: Ref<MultipleChoiceInputOption[]> = ref([]);

const initialize = () => {
    props.options.forEach(option => {
        const clone = JSON.parse(JSON.stringify(option));
        if ('actions' in option) {
            clone.blockActions = Block.convertLegacyBlockActions(clone.actions);
            delete clone.actions;
        }
        if (!(option.hideItem && option.renderType === BlockRenderType.NoRender)) blockOptions.value.push(clone);
        validValues.push(option.value);
    });

    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        props.instance.id
    );

    if (payload) {
        values.value = (props.instance.autoProgress || !props.instance.multiSelect) ? [payload.payload[(props.instance as Input).backendIdentifier]] : payload.payload[props.instance.backendIdentifier];
    }
    else if (props.initialValue && validValues.includes(props.initialValue)) {
        if (props.initialValue instanceof Array) {
            values.value = props.initialValue;
        } else {
            values.value.push(props.initialValue);
        }

        payloadStore.flowPayload?.setBlockPayload(buildPayload());
    }
};

onMounted(() => initialize());

const radioBorderColor = computed(() => {
    return [`fb-border-sol-${props.instance.color}`]
});

const radioBackgroundColor = computed(() => {
    return [`fb-bg-sol-${props.instance.color}`]
})

const layoutClasses = computed(() => {
    const gridCols = props.instance.useConditionalRendering
        ? blockOptions.value.length
        : props.instance.gridCols;

    switch(props.instance.layoutType) {
        case MultipleChoiceLayoutType.Grid:
            return `fb-grid ${useGridColumnOverride(gridCols, props.instance)} ${GRID_GAP_MAPPINGS[props.instance.gridGap - 1]}`;
        case MultipleChoiceLayoutType.Horizontally:
            return "fb-flex fb-flex-row fb-gap-2";
        case MultipleChoiceLayoutType.Vertically:
        default:
            return "fb-flex fb-flex-col fb-gap-2";
    }
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',{
        [props.instance.backendIdentifier]: (props.instance.autoProgress || !props.instance.multiSelect) ? values.value[0] : values.value
    },
    props.instance.id
);

const handleClick = async (option: MultipleChoiceInputOption) => {
    if (saving.value) return;

    if (props.instance.autoProgress) {
        saving.value = true;

        values.value = [option.value];

        payloadStore.flowPayload?.setBlockPayload(buildPayload());

        errorStore.slideErrors.resetSlideError(flowStore.sliderService.currentSlide?.id ?? '');

        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance as BlockJson,
            actions: option.blockActions,
        }

        await flowStore.update(
            payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
            consumerStore.bearerToken,
            newEvent
        ).catch(e => errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? ''));

        saving.value = false;
    }

    if (props.instance.multiSelect) {
        const index = values.value.indexOf(option.value);

        if (index === -1) values.value.push(option.value);
        else values.value.splice(index, 1);
    } else {
        values.value = [option.value];
    }

    payloadStore.flowPayload?.setBlockPayload(buildPayload());
}
</script>