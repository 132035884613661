<template>
    <div :class="layoutClasses">
        <div v-for="picture in engineSelectPictures" :key="picture.id" class="fb-flex fb-items-center fb-flex-col">
            <div
                class="fb-bg-white fb-active:fb-border-b-0 fb-p-2 hover:fb-shadow-sol-hover fb-cursor-pointer fb-w-full fb-aspect-square fb-overflow-hidden fb-flex fb-flex-col fb-items-center fb-justify-center"
                :class="[
                    instance.value === picture.id ? 'fb-border-sol-custom-5' : 'fb-border-sol-light-gray',
                    instance.roundImages && 'fb-rounded-lg',
                    instance.boxShadow && 'fb-shadow-sol-default fb-active:fb-shadow-none',
                    instance.showBorder && 'fb-border'
                ]"
                @click="handleClick(picture)"
            >
                <img
                    :src="picture.picture"
                    class="fb-w-auto fb-h-auto fb-overflow-hidden"
                    :class="{'fb-rounded-lg': instance.roundImages}"
                    :alt="picture.name"
                >
            </div>
            <p v-if="picture.name" class="fb-mt-2 fb-text-gray-600 fb-text-sm fb-text-center">{{ picture.name }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">

import {PictureSelectLayoutType} from "@flow-builder/core/src/Blocks/Core/Inputs/PictureSelect.ts";
import {computed, onMounted, Ref, ref, watch} from "vue";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useEngineStore} from "../../../Stores/engines.ts";
import EnginePictureSelect, {EnginePicture} from "@flow-builder/core/src/Blocks/Core/Inputs/EnginePictureSelect.ts";
import {useGridColumnOverride} from "@flow-builder/core/src/Components/Renderer/Composables/useGridColumnOverride.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";

const GRID_GAP_MAPPINGS = ['fb-gap-1', 'fb-gap-2', 'fb-gap-3', 'fb-gap-4', 'fb-gap-5', 'fb-gap-6', 'fb-gap-7', 'fb-gap-8', 'fb-gap-9', 'fb-gap-10', 'fb-gap-11', 'fb-gap-12'];

interface Props {
    instance: EnginePictureSelect,
}
const props = defineProps<Props>();

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const engineStore = useEngineStore();
const errorStore = useErrorStore();

const values = ref([]);

const engineSelectPictures: Ref<EnginePicture[]> = ref([]);
const pictureClickable: Ref<boolean> = ref(true);

const engineName: Ref<string|null> = ref(null);
const outputName: Ref<string|null> = ref(null);
const saving: Ref<boolean> = ref(false);

const initialize = () => {
    engineName.value = props.instance.dataSourceIdentifierData.engine ?? null;
    outputName.value = props.instance.dataSourceIdentifier ?? null;
    if (engineName.value && outputName.value) {
        watch(engineStore.outputs, handleOutputUpdate);
    }

    handleOutputUpdate(engineStore.outputs);
}

const handleOutputUpdate = (outputs) => {
    if (outputs[engineName.value]?.[outputName.value]?.value?.length) {
        updatePictures(outputs[engineName.value][outputName.value].value);
    }
}

const updatePictures = (pictures: EnginePicture[]) => {
    engineSelectPictures.value = pictures;
};

onMounted(() => initialize());

const layoutClasses = computed(() => {
    switch (props.instance.layoutType) {
        case PictureSelectLayoutType.Grid:
            return `fb-grid ${useGridColumnOverride(props.instance.gridCols, props.instance)} ${GRID_GAP_MAPPINGS[props.instance.gridGap - 1]} fb-items-start`;
        case PictureSelectLayoutType.Horizontally:
            return `fb-grid fb-gap-2 fb-grid-cols-${engineSelectPictures.value.length ?? 1} fb-items-start`
        case PictureSelectLayoutType.Vertically:
            return 'fb-flex fb-flex-col fb-gap-2'
    }
});

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [props.instance.backendIdentifier]: (props.instance.autoProgress || !props.instance.multiSelect) ? values.value[0] : values.value
    },
    props.instance.id
);

const handleClick = async (picture: EnginePicture) => {
    if (saving.value) return;
    saving.value = true;
    if (props.instance.autoProgress) {

        values.value = [picture.id];

        payloadStore.flowPayload?.setBlockPayload(buildPayload());

        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance as BlockJson,
        }

        await flowStore.update(
            payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
            consumerStore.bearerToken,
            newEvent,
        ).catch(e => {
            errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
            saving.value = false;
        });
    }

    if (props.instance.multiSelect) {
        const index = values.value.indexOf(picture.id);

        if (index === -1) values.value.push(picture.id);
        else values.value.splice(index, 1);
    } else {
        values.value = [picture.id];
    }

    payloadStore.flowPayload?.setBlockPayload(buildPayload());

    saving.value = false;
}

</script>