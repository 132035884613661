<template>
    <div
        v-if="errors.length"
        v-html="errors.map(err => err.messages.join('<br>')).join('<br>')"
        class="fb-bg-red-100 fb-border fb-border-red-400 fb-text-red-700 fb-px-4 fb-py-3 fb-rounded fb-relative fb-m-5"
    />
</template>

<script setup lang="ts">

import {useErrorStore} from "../../../Stores/errors.ts";
import {useFlowStore} from "../../../Stores/flow.ts";
import {computed} from "vue";

const errorStore = useErrorStore();
const flowStore = useFlowStore();

const errors = computed(() => errorStore.slideErrors.getSlideErrors(flowStore.sliderService.currentSlide?.id ?? ''));

</script>