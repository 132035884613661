import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {useEngineStore} from "../../Stores/engines.ts";
import SessionStorageService from "../SessionStorageService.ts";

declare global {
    interface Window {
        Pusher: any,
        Echo: any,
        previewMode: boolean,
    }
}

export interface ResultsChunk {
    job: string,
    part: number,
    total: number,
    data: string,
}

interface ChunkJobs {
    [key: string]: {
        lastChunkAt?: number,
        chunks: ResultsChunk[],
    }
}

export class EngineInteraction {
    protected echo: Echo|null;
    protected store: any;
    protected readyCallbacks: any[] = [];

    protected activeChunkJobs: ChunkJobs = {};
    protected maxChunkJobAge = 10000;

    constructor(protected pusher: Pusher) {
        this.echo = null;
        this.store = null;
    }

    protected getStore()
    {
        if(this.store !== null)
            return this.store;

        return this.store = useEngineStore();
    }

    public addReadyCallback(callback: any) {
        this.readyCallbacks.push(callback);
    }

    protected getEcho(bearer: string|null = null): Echo
    {
        if(this.echo !== null)
            return this.echo;

        const engineUrl = (window.previewMode && import.meta.env.VITE_PREVIEW_ENGINE_API_URL)
            ? import.meta.env.VITE_PREVIEW_ENGINE_API_URL
            : import.meta.env.VITE_ENGINE_API_URL;
        const pusherKey = (window.previewMode && import.meta.env.VITE_PREVIEW_PUSHER_APP_KEY)
            ? import.meta.env.VITE_PREVIEW_PUSHER_APP_KEY
            : import.meta.env.VITE_PUSHER_APP_KEY
        const pusherCluster = (window.previewMode && import.meta.env.VITE_PREVIEW_PUSHER_APP_CLUSTER)
            ? import.meta.env.VITE_PREVIEW_PUSHER_APP_CLUSTER
            : import.meta.env.VITE_PUSHER_APP_CLUSTER

        return this.echo = new Echo({
            broadcaster: 'pusher',
            key: pusherKey,
            cluster: pusherCluster,
            forceTLS: true,
            channelAuthorization: {
                endpoint: `${engineUrl}/broadcasting/auth`,
                params: {
                    bearer_token: bearer
                }
            }
        });
    }

    public listenForResults(bearer: string) {
        this.getEcho(bearer).private(`results.${bearer}`)
            .listen('ConsumerResultsUpdatedSync', this.onResultsChanged.bind(this))
            .listen('ConsumerResultsUpdated', this.onResultsChanged.bind(this)).subscribed(() => {
                this.readyCallbacks.forEach((cb) => cb());
                this.readyCallbacks = [];
            });
    }

    protected onResultsChanged(e: any): void {
        if ('job' in e.results) {
            const chunk: ResultsChunk = e.results;
            this.handleChunk(chunk);
        }
        else {
            this.commitResultsToStore(e.results);
        }
    }

    /**
     * Commit pusher results to engines store
     * @param results
     * @protected
     */
    protected commitResultsToStore(results: any): void {
        for(const engine of Object.keys(results)) {
            if(!this.getStore().outputs[engine])
                this.getStore().outputs[engine] = {};

            for (const output of Object.keys(results[engine])) {
                this.getStore().outputs[engine][output] = {
                    value: results[engine][output],
                    loading: false,
                    affectedValues: this.getStore().outputs[engine][output] ? this.getStore().outputs[engine][output].affectedValues : {}
                };
            }
        }

        new SessionStorageService().engineOutputs = this.getStore().outputs;
    }

    /**
     * Sort chunked results by job ID
     * @param chunk
     * @protected
     */
    protected handleChunk(chunk: ResultsChunk): void {
        this.activeChunkJobs[chunk.job] = this.activeChunkJobs[chunk.job] ?? {
            chunks: [],
        };
        this.activeChunkJobs[chunk.job].chunks[chunk.part] = (chunk);
        this.activeChunkJobs[chunk.job].lastChunkAt = Date.now();

        this.workChunkQueue();
    }

    /**
     * Check for completed chunk batches each time we receive one
     * Chunk jobs are unlikely to be longer than 2 or 3 parts
     * @protected
     */
    protected workChunkQueue(): void {
        for (const job in this.activeChunkJobs) {
            if (this.activeChunkJobs[job].chunks.length === this.activeChunkJobs[job].chunks[0]?.total) {
                const restoredString = this.activeChunkJobs[job].chunks.reduce((output, chunk) => output + chunk.data, '');

                try {
                    const results = JSON.parse(restoredString);
                    this.commitResultsToStore(results);
                }
                catch(e) {
                    console.error(e);
                }

                delete this.activeChunkJobs[job];
            }
            else if (this.activeChunkJobs[job].lastChunkAt && (Date.now() - (this.activeChunkJobs[job].lastChunkAt as number)) > this.maxChunkJobAge) {
                delete this.activeChunkJobs[job];
            }
        }
    }
}

export const initializeEngines = (): EngineInteraction => {
    window.Pusher = Pusher;

    return new EngineInteraction(window.Pusher);
}

const service: EngineInteraction = initializeEngines();

export default service;