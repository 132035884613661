<template>
    <div class="fb-grid"
         :class="{[gridClasses]: true}"
    >
        <BlockRenderer
                v-for="block in blocks"
                :key="block.id" :block="block"
                :class="`fb-col-span-${(block as Block).colSpan}`"
                :nested-slide="nestedSlide"
        />
    </div>
</template>

<script setup lang="ts">
import BlockRenderer from "../../WebRenderer/BlockRenderer.vue";
import Grid from "@flow-builder/core/src/Blocks/Core/Layout/Grid.ts";
import {computed} from "vue";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {useGridColumnOverride} from "@flow-builder/core/src/Components/Renderer/Composables/useGridColumnOverride.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";

interface Props {
    blocks: Block[],
    instance: Grid,
    nestedSlide?: Slide | null,
}
const props = defineProps<Props>();

const gridClasses = computed(() => {
    const classes = [];

    classes.push(useGridColumnOverride(props.instance.gridCols, props.instance, false));
    classes.push(`fb-gap-${props.instance.gap}`);
    classes.push(`fb-${props.instance.align}`);
    classes.push(`fb-${props.instance.justify}`);

    return classes.join(' ');
});
</script>