import {PageView} from "./Events/PageView.ts";
import {useFlowStore} from "../../Stores/flow.ts";

export type SlideEventPayload = {
    event: string,
    [key: string]: any
}

export interface TrackEvents {
    trackEvent(flowName: string, slideName: string, customEventPayload?: SlideEventPayload): void
}

class CalculatorEventService {
    eventClasses: TrackEvents[] = [];

    constructor() {
        this.eventClasses.push(new PageView());
    }

    trackEvents(slideName: string, customEventPayload?: SlideEventPayload) {
        const flowStore = useFlowStore();
        //@ts-ignore
        this.eventClasses.forEach((eventClass: TrackEvents) => eventClass.trackEvent(`${flowStore.flow?.name}`, `${slideName}`, customEventPayload));
    }
}

const calculatorEventService = new CalculatorEventService();

export default calculatorEventService;