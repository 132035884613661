<template>
    <BlockRenderer
        v-for="block in templateTree"
        :key="block.id" :id="block.id"
        :block="block"
        :nested-slide="nestedSlide"
    />
</template>

<script setup lang="ts">
import BlockRenderer from "../../WebRenderer/BlockRenderer.vue";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {computed} from "vue";
interface Props {
    slideTemplate: Slide,
    nestedSlide: Slide,
}
const props = defineProps<Props>();

const templateTree = computed(() => props.slideTemplate?.hierarchy ?? props.nestedSlide?.hierarchy);

</script>