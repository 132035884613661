<template>
    <div class="map-marker-container fb-h-96 fb-rounded-lg" ref="container"></div>
</template>

<script setup lang="ts">

import {Google, GoogleMapsAPI} from "@flow-builder/core/src/Services/External/Google.ts";
import {onBeforeMount, onMounted, ref, Ref} from "vue";
import MapMarker, {ZoomControlPosition} from "@flow-builder/core/src/Blocks/Core/Inputs/MapMarker.ts";
import {useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {usePayload} from "../../../Composables/payload.ts";
import {useEngineStore} from "../../../Stores/engines.ts";
import {useCompatibilityStore} from "../../../Stores/compatibility.ts";

type Map = google.maps.Map;
type AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;
type MapOptions = google.maps.MapOptions;

const googleService: Ref<GoogleMapsAPI | null> = ref(null);
const map: Ref<Map | null> = ref(null);
const marker: Ref<AdvancedMarkerElement | null> = ref(null);
const container: Ref<HTMLElement|null> = ref(null);
const lat = ref(0);
const lng = ref(0);

const props = defineProps({
    instance: {
        type: Object,
        default: null
    },
    latitude: {
        type: Number,
        default: 40.6037625
    },
    longitude: {
        type: Number,
        default: -122.3536886
    }
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const engineStore = useEngineStore();
const compatibilityStore = useCompatibilityStore();

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        {
            latitude: null,
            longitude: null
        },
        (props.instance as Block).id
    );
});

onMounted(() => initialize());

const initialize = () => {
    const service = new Google();
    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        (props.instance as MapMarker).id
    );

    lat.value = engineStore.getEngineInputByKey('latitude') ? engineStore.getEngineInputByKey('latitude') : (payload ? payload.payload['latitude'] : null);
    lng.value = engineStore.getEngineInputByKey('longitude') ? engineStore.getEngineInputByKey('longitude') : (payload ? payload.payload['longitude'] : null);

    service.loadService().then(() => {
        googleService.value = service.getService()

        initializeMapMarker();
    });

}

const initializeMapMarker = () => {
    const location = new (googleService.value as GoogleMapsAPI).LatLng(
        lat.value,
        lng.value
    );

    const newMapOptions: MapOptions = {
        zoom: Number((props.instance as MapMarker).zoom),
        center: location,
        scrollwheel: false,
        tilt: 0,
        disableDefaultUI: true,
        zoomControl: (props.instance as MapMarker).zoomControl,
        zoomControlOptions: {
            position: getZoomControlPosition((props.instance as MapMarker).zoomControlPosition)
        },
        mapTypeId: "hybrid",
        mapId: 'b069a47abdebf114',
    };

    if (compatibilityStore.disableVectorMap)
        newMapOptions.renderingType = google?.maps?.RenderingType?.RASTER ?? 'RASTER';

    const newMap = new (googleService.value as GoogleMapsAPI).Map(container.value as HTMLElement, newMapOptions);

    const newMarker = new (googleService.value as GoogleMapsAPI).marker.AdvancedMarkerElement({
        position: location,
        map: newMap,
        gmpDraggable: (props.instance as MapMarker).draggable
    });

    map.value = newMap;
    marker.value = newMarker;

    (googleService.value as GoogleMapsAPI).event.addListener(marker.value, 'dragend', (e: any) => {
        payloadStore.flowPayload?.setBlockPayload(
            new BlockPayload(
                flowStore.sliderService.currentSlide?.id ?? '',
                {
                    latitude: e.latLng.lat(),
                    longitude: e.latLng.lng()
                },
                (props.instance as Block).id
            )
        );
    });
}

const getZoomControlPosition = (position: ZoomControlPosition) => {
    switch (position) {
        case ZoomControlPosition.LeftTop:
            return googleService.value?.ControlPosition.LEFT_TOP;
        case ZoomControlPosition.LeftBottom:
            return googleService.value?.ControlPosition.LEFT_BOTTOM;
        case ZoomControlPosition.RightTop:
            return googleService.value?.ControlPosition.RIGHT_TOP;
        case ZoomControlPosition.RightBottom:
            return googleService.value?.ControlPosition.RIGHT_BOTTOM;
        default:
            return googleService.value?.ControlPosition.LEFT_TOP;
    }
}

</script>

