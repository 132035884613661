<template>
    <div :class="[textAlignment]">{{ count }} {{ instance.label }}</div>
</template>

<script setup lang="ts">
import {PropType, ref, onMounted, computed} from "vue";
import Countdown from "@flow-builder/core/src/Blocks/Core/Display/Countdown.ts";
import {BlockAlignment} from "@flow-builder/core/src/Blocks/Core/Block.ts";

const props = defineProps({
    instance: {
        type: Object as PropType<Countdown>,
        required: true
    }
});

const count = ref(0);

const timer = () => {
    if (count.value <= props.instance.stop) return;

    let newValue = count.value - 1;

    if (newValue < props.instance.stop) newValue = props.instance.stop;

    count.value = newValue;

    setTimeout(timer, props.instance.speed * 1000);
}

onMounted(() => {
    count.value = props.instance.start;

    setTimeout(timer, props.instance.speed * 1000);
});

const textAlignment = computed(() => {
    switch(props.instance?.blockAlignment) {
        case BlockAlignment.Left:
            return "fb-text-left";
        case BlockAlignment.Center:
            return "fb-text-center";
        case BlockAlignment.Right:
            return "fb-text-right";
        default:
            return "";
    }
});

</script>