<template>
    <Suspense>
        <Client
            id="flow-client"
            :flow="flow"
            :initial="formattedInitialData"
            :revision="revision"
            :working-revision="workingRevision"
        />
    </Suspense>
</template>

<script setup lang="ts">
import Client from "./Client.vue";
import {computed} from "vue";
import { useCompatibilityStore } from "./Stores/compatibility.ts";

const props = defineProps({
    flow: {
        type: String,
        default: null
    },
    revision: {
        type: String,
        default: null,
    },
    initial: {
        type: String,
        default: null
    },
    workingRevision: {
        type: Boolean,
        default: false
    },
    previewMode: {
        type: [String, Boolean],
        default: false,
    }
});

// load any polyfill/workaround needed for outdated browsers
const compatibilityStore = useCompatibilityStore();
compatibilityStore.initialize();
if (compatibilityStore.hasCompatibilityIssues)
    compatibilityStore.compatibilityMessages.forEach(msg => console.warn(msg));

window.previewMode = !!props.previewMode;

const formattedInitialData = computed(() => props.initial !== null ? JSON.parse(props.initial as string) : null);
</script>