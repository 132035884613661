import Input from "@flow-builder/core/src/Blocks/Core/Inputs/Input.ts";
import Error from "../Errors/Error.ts";
import {useErrorStore} from "../Stores/errors.ts";
import {useEngineStore} from "../Stores/engines.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import InputField, {InputFieldTypes} from "@flow-builder/core/src/Blocks/Core/Inputs/InputField.ts";
import {usePayloadStore} from "../Stores/payload.ts";

export interface CustomInputValidator {
    blockType: string,
    inputType?: InputFieldTypes,
    validator(block: InputField): boolean,
    message: string,
}

export default class InputValidationService {

    protected errorStore = useErrorStore();
    protected engineStore = useEngineStore();
    protected payloadStore = usePayloadStore();

    protected customValidators: CustomInputValidator[] = [
        {
            blockType: 'address-field',
            validator: (_block: InputField) => {
                const requiredFields = ["street_name", "zip_code"];

                for (let i = 0; i < requiredFields.length; i++) {
                    if (!this.engineStore.inputs.lead?.[requiredFields[i]]) {
                        return false;
                    }
                }

                return true;
            },
            message: "Please select an Address value from the dropdown list in the Address field."
        },
        {
            blockType: 'address-field',
            validator: (_block: InputField) => {
                const requiredFields = ["street_number"];

                for (let i = 0; i < requiredFields.length; i++) {
                    if (!this.engineStore.inputs.lead?.[requiredFields[i]]) {
                        return false;
                    }
                }

                return true;
            },
            message: "Please select an Address with a street number"
        },
        {
            blockType: 'input-field',
            inputType: InputFieldTypes.Phone,
            validator: (block: InputField) => {
                const targetPayload = this.payloadStore.getPayloadByBlockId(block.id);
                const engineInput = block.backendIdentifier;
                if (engineInput !== 'phone') return true;
                const [inputKey, inputValue] = Object.entries(targetPayload)[0];
                if (inputValue) {
                    const cleanPhone = inputValue.replace(/\D/g, '');
                    if (cleanPhone && cleanPhone.length >= 10) {
                        targetPayload[inputKey] = cleanPhone;
                        this.attemptUpdateEngineInput(block, cleanPhone);

                        return true;
                    }
                }

                return false;
            },
            message: "Please enter a valid phone number",
        }
    ];

    public validateInputs(slide: Slide): boolean {
        this.errorStore.slideErrors.resetSlideError(slide.id);
        const requiredInputs = this.findRequiredInputs(slide.hierarchy as Input[]);

        if (requiredInputs.length) {
            for (let i = 0; i < requiredInputs.length; i++) {
                const validators = this.customValidators.filter(validator => {
                    //@ts-ignore
                    return (validator.blockType === requiredInputs[i].type)
                        && (!validator.inputType || validator.inputType === (requiredInputs[i] as InputField).inputType);
                });
                if (validators.length) {
                    for (let j = 0; j < validators.length; j++) {
                        if (!validators[j].validator(requiredInputs[i])) {
                            this.errorStore.slideErrors.addError(new Error(
                                slide.id ?? 'id',
                                [validators[j].message],
                            ));

                            return false;
                        }
                    }
                }
                else {
                    const engine = requiredInputs[i].backendIdentifierData?.engine ?? null;
                    if (!engine) continue;
                    const targetInput = this.engineStore.inputs?.[engine]?.[requiredInputs[i].backendIdentifier] ?? null;
                    if (targetInput == undefined || !(targetInput.toString().trim())) {
                        //@ts-ignore
                        const displayName = requiredInputs[i].label || requiredInputs[i].name || requiredInputs[i].id;
                        this.errorStore.slideErrors.addError(new Error(
                            slide.id ?? 'id',
                            [`The field "${displayName}" is required.`],
                        ));

                        return false;
                    }
                }
            }
        }

        return true;
    }

    public findRequiredInputs(slideHierarchy: Input[]) {
        const slideRequiredInputs: InputField[] = [];

        const searchBlocks = (blocks: Input[]): void => {
            (blocks as InputField[]).map(block => {
                if (block.blocks?.length) {
                    searchBlocks(block.blocks as Input[]);
                } else {
                    if ('required' in block && block.required && block.backendIdentifier) {
                        slideRequiredInputs.push(block);
                    }
                }
            });
        }
        searchBlocks(slideHierarchy);

        return slideRequiredInputs;
    }

    protected attemptUpdateEngineInput(block: InputField, inputValue: any): void {
        const engineName = block.backendIdentifierData?.engine;
        const inputName = block.backendIdentifier;
        if (engineName && inputName) {
            const target = this.engineStore.inputs[engineName];
            if (inputName in target) target[inputName] = inputValue;
        }
    }

}