import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import FlowPayload from "../Payload/FlowPayload.ts";

export const usePayloadStore = defineStore('payloads', () => {
    const flowPayload: Ref<FlowPayload | null> = ref(null);

    const getPayloadByBlockId = (blockId: string): {[key: string]: string} => {
        const targetPayload = flowPayload.value?.flowPayload.find(payload => payload.blockId === blockId);
        return targetPayload?.payload ?? {};
    }

    return {
        flowPayload,

        getPayloadByBlockId,
    }
});