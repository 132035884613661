import BlockPayload from "../Payload/BlockPayload.ts";
import {usePayloadStore} from "../Stores/payload.ts";
import {useFlowStore} from "../Stores/flow.ts";
import {useConsumerStore} from "../Stores/consumer.ts";
import {EngineContract, EngineInputs, EngineOutputs, useEngineStore} from "../Stores/engines.ts";
import {BranchHistoryStorage} from "./BranchHistory.ts";

export default class SessionStorageService {
    protected payloadStore = usePayloadStore();
    protected flowStore = useFlowStore();
    protected consumerStore = useConsumerStore();
    protected engineStore = useEngineStore();

    public get consumerToken(): string | null {
        return sessionStorage.getItem(`${this.flowStore.flow?.getFlowName() ?? 'flow'}-consumer-token`);
    }

    public set consumerToken(token: string) {
        sessionStorage.setItem(`${this.flowStore.flow?.getFlowName() ?? 'flow'}-consumer-token`, token);
        sessionStorage.setItem(token, JSON.stringify({
            slide_history: [],
            branch_history: [],
            payload: [],
            engines: [],
            engine_inputs: {},
            engine_outputs: {}
        }));
    }

    public get consumerSlideHistory(): string[] {
        if (!this.consumerToken) return [];

        //@ts-ignore
        return JSON.parse(sessionStorage.getItem(this.consumerToken))['slide_history'];
    }

    public set consumerSlideHistory(history: string[]) {
        if (!this.consumerToken) return;

        //@ts-ignore
        const consumerData = JSON.parse(sessionStorage.getItem(this.consumerToken));

        consumerData.slide_history = history;

        sessionStorage.setItem(this.consumerToken, JSON.stringify(consumerData));
    }

    public get consumerBranchHistory(): BranchHistoryStorage | null {
        if (!this.consumerToken) return null;

        //@ts-ignore
        return JSON.parse(sessionStorage.getItem(this.consumerToken))['branch_history'];
    }

    public set consumerBranchHistory(history: BranchHistoryStorage) {
        if (!this.consumerToken) return;

        //@ts-ignore
        const consumerData = JSON.parse(sessionStorage.getItem(this.consumerToken));

        consumerData.branch_history = history;

        sessionStorage.setItem(this.consumerToken, JSON.stringify(consumerData));
    }

    public get consumerPayload(): BlockPayload[] {
        if (!this.consumerToken) return [];

        //@ts-ignore
        return JSON.parse(sessionStorage.getItem(this.consumerToken))['payload'];
    }

    public set consumerPayload(payload: BlockPayload[]) {
        if (!this.consumerToken) return;

        //@ts-ignore
        const consumerData = JSON.parse(sessionStorage.getItem(this.consumerToken));

        consumerData.payload = payload;

        sessionStorage.setItem(this.consumerToken, JSON.stringify(consumerData));
    }

    public get engines(): EngineContract[] {
        if (!this.consumerToken) return [];

        //@ts-ignore
        return JSON.parse(sessionStorage.getItem(this.consumerToken))['engines'];
    }

    public set engines(engines: EngineContract[]) {
        if (!this.consumerToken) return;

        //@ts-ignore
        const consumerData = JSON.parse(sessionStorage.getItem(this.consumerToken));

        consumerData.engines = engines;

        sessionStorage.setItem(this.consumerToken, JSON.stringify(consumerData));
    }

    public get engineInputs(): EngineInputs {
        if (!this.consumerToken) return {};

        //@ts-ignore
        return JSON.parse(sessionStorage.getItem(this.consumerToken))['engine_inputs'];
    }

    public set engineInputs(inputs: EngineInputs) {
        if (!this.consumerToken) return;

        //@ts-ignore
        const consumerData = JSON.parse(sessionStorage.getItem(this.consumerToken));

        consumerData.engine_inputs = inputs;

        sessionStorage.setItem(this.consumerToken, JSON.stringify(consumerData));
    }

    public get engineOutputs(): EngineOutputs {
        if (!this.consumerToken) return {};

        //@ts-ignore
        return JSON.parse(sessionStorage.getItem(this.consumerToken))['engine_outputs'];
    }

    public set engineOutputs(outputs: EngineOutputs) {
        if (!this.consumerToken) return;

        //@ts-ignore
        const consumerData = JSON.parse(sessionStorage.getItem(this.consumerToken));

        consumerData.engine_outputs = outputs;

        sessionStorage.setItem(this.consumerToken, JSON.stringify(consumerData));
    }

    public initializeFromSessionStorage(){
        if (!this.consumerToken) return;

       const history = this.consumerSlideHistory;

        const currentSlideId = history.pop();

        this.flowStore.sliderService.setSlideHistory(history);
        this.flowStore.sliderService.setBranchHistory(this.consumerBranchHistory);
        this.payloadStore.flowPayload?.setFlowPayload(this.consumerPayload);

        this.consumerStore.bearerToken = this.consumerToken;
        this.engineStore.engines = this.engines;
        this.engineStore.inputs = this.engineInputs;
        this.engineStore.outputs = this.engineOutputs;

        this.flowStore.sliderService.initializeService(currentSlideId === undefined);

        //@ts-ignore
        this.engineStore.service.listenForResults(this.consumerToken);

        if (currentSlideId) this.flowStore.sliderService.findSlideInFlowAndBranches(currentSlideId);

        //@ts-ignore
        window.calculatorRendered = true;

        // @ts-ignore
        window.calculatorLoaded = true;
    }
}