<template>
    <div :class="layoutClasses">
        <div v-for="picture in selectPictures" :key="picture.id" class="fb-flex fb-items-center fb-flex-col"
            :class="[picture.hideItem && picture.renderType === BlockRenderType.Hidden && 'fb-invisible']"
        >
            <div
                v-if="!(picture.hideItem && picture.renderType === BlockRenderType.NoRender)"
                class="fb-relative fb-active:fb-border-b-0 hover:fb-shadow-sol-hover fb-cursor-pointer fb-w-full fb-aspect-square fb-overflow-hidden fb-flex fb-flex-col fb-items-center fb-justify-center"
                :class="[
                    values.includes(picture.value) ? 'fb-border-sol-custom-5' : 'fb-border-sol-light-gray',
                    picture.type === Types.Fill ? getButtonColor(picture.color ?? '') : '',
                    instance.roundImages && 'fb-rounded-lg',
                    instance.boxShadow && 'fb-shadow-sol-default fb-active:fb-shadow-none',
                    instance.showBorder && 'fb-border',
                ]"
                @click="handleClick(picture)"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                     class="fb-w-full fb-h-full"
                     :class="`fb-text-sol-${instance.iconColor}`"
                     v-html="picture.content"
                     v-if="picture.type === Types.Icon && picture.content"
                >
                </svg>
                <img
                    :src="picture.content"
                    v-if="picture.type === Types.Picture && picture.content"
                    class="fb-w-auto fb-h-auto fb-overflow-hidden"
                    :alt="picture.label"
                >
                <div v-if="picture.overlay"
                     class="fb-absolute fb-w-full fb-h-full fb-flex fb-items-center fb-justify-center"
                >
                    <div>{{ picture.overlay }}</div>
                </div>
            </div>

            <div v-if="picture.label && !picture.hideItem" class="fb-mt-2 fb-text-gray-600 fb-text-sm fb-text-center">{{ picture.label }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">

import PictureSelect, {
    PictureSelectLayoutType,
    SelectedPicture,
    Types
} from "@flow-builder/core/src/Blocks/Core/Inputs/PictureSelect.ts";
import {computed, onBeforeMount, onMounted, ref, Ref} from "vue";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useGridColumnOverride} from "@flow-builder/core/src/Components/Renderer/Composables/useGridColumnOverride.ts";
import {usePayload} from "../../../Composables/payload.ts";
import {useGradientClasses} from "@flow-builder/core/src/Components/Renderer/Composables/useGradientClasses.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import Block, {BlockJson, BlockRenderType} from "@flow-builder/core/src/Blocks/Core/Block.ts";

const GRID_GAP_MAPPINGS = ['fb-gap-1', 'fb-gap-2', 'fb-gap-3', 'fb-gap-4', 'fb-gap-5', 'fb-gap-6', 'fb-gap-7', 'fb-gap-8', 'fb-gap-9', 'fb-gap-10', 'fb-gap-11', 'fb-gap-12'];

interface Props {
    pictures: SelectedPicture[],
    instance: PictureSelect,
    initialValue?: string | number | [string|number],
}
const props = defineProps<Props>();

const values = ref([]);
const selectPictures: Ref<SelectedPicture[]> = ref([]);
const validValues = [];
const pictureClickable = ref(true);
const saving: Ref<boolean> = ref(false);

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

const initialize = () => {
    props.pictures?.forEach(picture => {
        if (!(picture.hideItem && picture.renderType === BlockRenderType.NoRender)) selectPictures.value.push(picture);
        validValues.push(picture.value);
    });

    // Convert legacy actions
    if ('actions' in props.instance) {
        PictureSelect.convertSinglePictureBlockActions(Block.convertLegacyBlockActions(props.instance.actions), selectPictures.value);
    }
    else if (props.instance.blockActions) {
        PictureSelect.convertSinglePictureBlockActions(props.instance.blockActions, selectPictures.value);
    }


    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        props.instance.id
    );

    if (payload) {
        values.value = (props.instance.autoProgress || !props.instance.multiSelect) ? [payload.payload[props.instance.backendIdentifier]] : payload.payload[props.instance.backendIdentifier];
    }
    else if (props.initialValue && validValues.includes(props.initialValue)) {
        if (props.initialValue instanceof Array) {
            values.value = props.initialValue;
        } else {
            values.value.push(props.initialValue);
        }
        payloadStore.flowPayload?.setBlockPayload(buildPayload());
    }
};

onMounted(() => initialize());

const layoutClasses = computed(() => {
    const gridCols = props.instance.useConditionalRendering
        ? selectPictures.value.length
        : props.instance.gridCols;

    switch (props.instance.layoutType) {
        case PictureSelectLayoutType.Grid:
            return `fb-grid ${useGridColumnOverride(gridCols, props.instance)} ${GRID_GAP_MAPPINGS[props.instance.gridGap - 1]} fb-items-start`;
        case PictureSelectLayoutType.Horizontally:
            return `fb-grid fb-gap-2 fb-grid-cols-${selectPictures.value.length ?? 1} fb-items-start`
        case PictureSelectLayoutType.Vertically:
            return 'fb-flex fb-flex-col fb-gap-2'
    }
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [props.instance.backendIdentifier]: (props.instance.autoProgress || !props.instance.multiSelect) ? values.value[0] : values.value
    },
    props.instance.id
);

const handleClick = async (picture: SelectedPicture) => {
    if (saving.value) return;
    if (!pictureClickable.value) return;

    if (props.instance.autoProgress) {
        saving.value = true;

        values.value = [picture.value];
        payloadStore.flowPayload?.setBlockPayload(buildPayload());

        errorStore.slideErrors.resetSlideError(flowStore.sliderService.currentSlide?.id ?? '');

        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance as BlockJson,
            actions: picture.blockActions,
        }

        flowStore.update(
            payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
            consumerStore.bearerToken,
            newEvent
        ).catch(e => {
            errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
        });

        saving.value = false;
    }

    if (props.instance.multiSelect) {
        const index = values.value.indexOf(picture.value);

        if (index === -1) values.value.push(picture.value);
        else values.value.splice(index, 1);
    } else {
        values.value = [picture.value];
    }

    payloadStore.flowPayload?.setBlockPayload(buildPayload());
}

const getButtonColor = (color: string) => {
    return color
        ? /^gradient/i.test(color)
            ? useGradientClasses(color)
            : `fb-bg-sol-${color}`
        : 'fb-bg-white ';
}


</script>