import BlockPayload from "./BlockPayload.ts";
import SessionStorageService from "../Services/SessionStorageService.ts";

export interface FlowPayloadInterface {
    setBlockPayload(blockPayload: BlockPayload): void

    getBlockPayload(slideId: string, blockId: string): BlockPayload | null

    getSlidePayload(slideId: string): Object
}

export default class FlowPayload implements FlowPayloadInterface {
    public flowPayload: BlockPayload[] = [];
    public initialPayloads: BlockPayload[] = [];
    public sessionStorageService = new SessionStorageService();

    setInitialBlockPayload(blockPayload: BlockPayload): void {
        const payload = this.initialPayloads.find((pl: BlockPayload) => pl.slideId === blockPayload.slideId && pl.blockId === blockPayload.blockId);

        if (!payload) this.initialPayloads.push(blockPayload)
        else {
            this.initialPayloads[this.initialPayloads.indexOf(payload)] = blockPayload;
        }
    }

    getInitialSlidePayload(slideId: string): object {
        return this.initialPayloads.filter((payload: BlockPayload) => payload.slideId === slideId).map((payload: BlockPayload) => {
            return payload.payload;
        }).reduce((accumulator, currentValue) => {
            return {...accumulator, ...currentValue};
        }, {})
    }

    setBlockPayload(blockPayload: BlockPayload): void {
        this.clearDuplicateEngineInputs(blockPayload);
        const payload = this.flowPayload.find((pl: BlockPayload) => pl.slideId === blockPayload.slideId && pl.blockId === blockPayload.blockId);

        if (!payload) this.flowPayload.push(blockPayload)
        else {
            this.flowPayload[this.flowPayload.indexOf(payload)] = blockPayload;
        }

        this.sessionStorageService.consumerPayload = this.flowPayload;
    }

    getSlidePayload(slideId: string): Object {
        return this.flowPayload.filter((payload: BlockPayload) => payload.slideId === slideId).map((payload: BlockPayload) => {
            return payload.payload;
        }).reduce((accumulator, currentValue) => {
            return {...accumulator, ...currentValue};
        }, {})
    }

    getBlockPayload(slideId: string, blockId: string): BlockPayload | null {
        return this.flowPayload.find((payload: BlockPayload) => payload.slideId === slideId && payload.blockId === blockId) ?? null;
    }

    public setFlowPayload(blockPayloads: BlockPayload[]) {
        this.flowPayload = blockPayloads;
    }

    /**
     *    If multiple inputs on a Slide use the same engine input key, we need to clear the values when we set a block payload
     *      or else getSlidePayload() may send an incorrect value to Engines
     */
    clearDuplicateEngineInputs(settingPayload: BlockPayload): void {
        const inputKey = Object.keys(settingPayload.payload)?.[0];
        if (inputKey) {
            for (let i = this.flowPayload.length - 1; i >= 0; i--) {
                if (this.flowPayload[i].slideId === settingPayload.slideId
                    && this.flowPayload[i].blockId !== settingPayload.blockId
                    && Object.keys(this.flowPayload[i].payload)?.[0] === inputKey
                ) {
                    this.flowPayload.splice(i, 1);
                }
            }
        }
    }
}