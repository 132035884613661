import Slide, {CustomSlideEvent, CustomSlideEventTrigger} from "@flow-builder/core/src/Slides/Slide.ts";
import calculatorEventService from "../Services/Analytics/CalculatorEventService.ts";
import {useEngineStore} from "../Stores/engines.ts";
import {BaseAction, SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";

/**
 * Dispatches legacy custom Slide events
 * @param currentSlide
 * @param eventType
 */
export const dispatchLegacyEvents = (currentSlide: Slide | null, eventType: CustomSlideEventTrigger): void => {
    const events = currentSlide?.customEvents
        ? currentSlide.customEvents
        : currentSlide?.event && eventType === CustomSlideEventTrigger.SLIDE_LOAD
            ? [{ name: currentSlide.event, trigger: CustomSlideEventTrigger.SLIDE_LOAD }]
            : null;
    if (!events) return;

    const validEvents = events.filter(event => event.trigger === eventType);
    validEvents.forEach(event => {
        const customEventPayload = event.trigger === CustomSlideEventTrigger.SLIDE_SUBMIT
            ? { event: event.name, ...getInputPayload(event) }
            : undefined
        calculatorEventService.trackEvents(event.name, customEventPayload);
    });
}

/**
 * Dispatch a GTM event using new SlideAction interface
 * @param action
 */
export const dispatchGtmEvent = (action: BaseAction): void => {
    if (!action.name) return;
    //TODO: remove the action.payload check once we're sure all custom events are using the new GTMCustom action type
    if (action.type === SlideActionType.GTMCustom || action.payload?.length) {
        const customPayloadEvent = { event: action.name, ...getInputPayload(action) }
        calculatorEventService.trackEvents(action.name, customPayloadEvent);
    }
    else {
        calculatorEventService.trackEvents(action.name, undefined);
    }
}

const getInputPayload = (event: BaseAction | CustomSlideEvent): {} => {
    if (!event.payload) return {};
    const engineStore = useEngineStore();

    return (event.payload as {keyName: string, engineName: string, inputName: string}[])?.reduce((output, inputDetail) => {
        const targetData = engineStore.inputs?.[inputDetail.engineName]?.[inputDetail.inputName];
        return targetData != null
            ? { ...output, [inputDetail.keyName]: targetData }
            : output;
    }, {});
}