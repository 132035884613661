<template>
    <ImagePreview v-model="image" />
</template>

<script lang="ts" setup>
import EngineImage from "@flow-builder/core/src/Blocks/Core/Display/EngineImage.ts";
import ImagePreview from "@flow-builder/core/src/Components/Renderer/ImagePreview.vue";
import {useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useEngineStore} from "../../../Stores/engines.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {computed, ref, Ref} from "vue";
import {watch} from "vue";

interface Props {
    instance: EngineImage
}

const props = defineProps<Props>()

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const engineStore = useEngineStore();
const errorStore = useErrorStore();

const image: Ref<string|null> = ref(null);
const engineName: Ref<string|null> = ref(null);
const outputName: Ref<string|null> = ref(null);

const handleOutputUpdate = (outputs) => {
    if (outputs[engineName.value]?.[outputName.value]?.value) {
        image.value = outputs[engineName.value]?.[outputName.value]?.value;
    }
}

engineName.value = props.instance.dataSourceIdentifierData.engine ?? null;
outputName.value = props.instance.dataSourceIdentifier ?? null;

if (engineName.value && outputName.value) {
    watch(engineStore.outputs, handleOutputUpdate);
}

handleOutputUpdate(engineStore.outputs);
</script>