import {
    BaseAction,
    SlideAction,
    SlideActionJson,
    SlideActionType,
    TerminatingActionType
} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {ActionCondition, ActionConditionComparisonSourceType} from "@flow-builder/core/src/Blocks/Core/Conditions/ActionCondition.ts";
import {
    getDefaultSlideLifecycleEvents,
    SlideLifecycleEvent,
    SlideLifecycleEventHandlerContract,
    SlideLifecycleEventType,
    SlideLifecycleInputCollection
} from "@flow-builder/core/src/Blocks/Core/Events/SlideLifecycleEvents.ts";
import {useFlowStore} from "../Stores/flow.ts";

export class SlideLifecycleEventHandler implements SlideLifecycleEventHandlerContract {

    protected type?: SlideLifecycleEventType;
    protected actionQueue: SlideAction[] = [];
    protected flowStore = useFlowStore();

    constructor() {}

    public trigger(triggeringEvent: SlideLifecycleEvent, inputValueCollection: SlideLifecycleInputCollection, currentSlide: Slide|null): void {
        this.type = triggeringEvent.type;
        const lifeCycleEventActions = currentSlide?.slideLifecycleEventActions ?? getDefaultSlideLifecycleEvents();
        if (this.type in lifeCycleEventActions) {
            this.actionQueue = this.fromJson(lifeCycleEventActions[this.type]);
        }

        const queuedActions: BaseAction[] = [];
        let terminated = false;

        for (const action of this.actionQueue) {
            if (action.shouldExecute(inputValueCollection)) {
                queuedActions.push(action.getAction());
                if (action.shouldTerminate()) {
                    terminated = true;
                    break;
                }
            }
        }

        if (!terminated) {
            const triggerActions = JSON.parse(JSON.stringify(triggeringEvent.actions ?? []));

            if (triggeringEvent.type === SlideLifecycleEventType.SLIDE_SUBMIT && triggerActions.length === 0) {
                triggerActions.push({ type: SlideActionType.NextSlide, target: null });
            }

            for (const action of triggerActions) {
                queuedActions.push(action);
                if (TerminatingActionType.includes(action.type)) break;
            }
        }

        this.flowStore.sliderService.handleAction(queuedActions);
    }

    public fromJson(lifecycleEventActions: SlideActionJson[]): SlideAction[] {
        const output: SlideAction[] = [];
        lifecycleEventActions.forEach(slideAction => {
            try {
                const conditions: ActionCondition[] = slideAction.conditions.map(condition => new ActionCondition(
                    condition.inputTarget,
                    condition.modifier,
                    condition.operator,
                    condition.compareTarget ?? {
                        type: ActionConditionComparisonSourceType.RawValue,
                        engineName: "",
                        value: condition.targetValue ?? "",
                    }
                ));
                const newAction: SlideAction = new SlideAction(
                    slideAction.action.type,
                    slideAction.name,
                    slideAction.action.target ?? null,
                    conditions ?? null,
                    slideAction.action.payload ?? null
                );
                output.push(newAction);
            }
            catch(e) {
                console.error('Failed to re-instantiate slideAction.', e);
            }
        });

        return output;
    }
}