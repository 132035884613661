<template>
    <div class="fb-mx-auto hover:fb-border-sol-primary fb-cursor-pointer fb-flex fb-flex-col fb-items-center fb-px-6 fb-py-3 fb-bg-white fb-border fb-rounded-lg fb-text-sol-black fb-max-w-fit fb-gap-1 fb-shadow">
        <span class="fb-font-bold fb-text-2xl">{{ dateInfo[0] }}</span>
        <span class="fb-font-semibold fb-text-xl">{{ dateInfo[1] }}</span>
        <span class="fb-font-semibold fb-rounded fb-bg-gray-200 fb-p-2">({{ dateInfo[2] }})</span>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";

const props = defineProps({
    date: {
        type: [Date, String],
        required: true
    },
});

/**
 * Return just day in number, month and day name
 * Example: [12, Jul, Wed]
 * @param date
 */
const getInfoFromDate = (date: string) => {
    const [_, ...info] = new Date(date).toDateString().split(' ').reverse();
    return info;
}


const dateInfo = computed(() => {
    return getInfoFromDate(props.date.toString())
})

</script>