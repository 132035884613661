<template>
    <div class="fb-flex fb-flex-col">
        <div class="fb-text-gray-500 fb-text-sm fb-font-bold fb-mb-1" v-if="label && label.trim().length > 0">
            {{ label }}
        </div>
        <div class="fb-w-full fb-flex fb-items-center">
            <div style="height: calc(16px + 1.5rem)"
                 class="fb-h-full fb-bg-gray-100 fb-py-2 fb-px-4 fb-rounded-l fb-flex fb-items-center fb-border-l fb-border-y fb-border-gray-200 fb-text-gray-600 fb-font-medium"
                 v-if="(instance as InputField).prefix !== null">
                {{ (instance as InputField).prefix }}
            </div>
            <input
                    class="fb-flex-1 fb-border-y fb-border-gray-200"
                    :class="{
                        'fb-rounded-l fb-border-l': (instance as InputField).prefix === null,
                        'fb-rounded-r fb-border-r': (instance as InputField).suffix === null,
                        [textAlignment]: true,
                        [`fb-border-sol-${(instance as InputField).borderColor}`]: (instance as InputField).borderColor,
                    }"
                    :disabled="(instance as InputField).disabled"
                    :type="type"
                    :placeholder="placeholder"
                    v-model="value"
                    :style="innerStyles"
                    :name="(instance as InputField).inputName ?? ''"
                    @keyup="handleKeyup"
                    ref="inputEl"
            />
            <div style="height: calc(16px + 1.5rem)"
                 class="fb-h-full fb-bg-gray-100 fb-py-2 fb-px-4 fb-rounded-r fb-flex fb-items-center fb-border-r fb-border-y fb-border-gray-200 fb-text-gray-600 fb-font-medium"
                 v-if="(instance as InputField).suffix !== null">
                {{ (instance as InputField).suffix }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import InputField from "@flow-builder/core/src//Blocks/Core/Inputs/InputField.ts";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import Block, {BlockAlignment, BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {usePayload} from "../../../Composables/payload.ts";
import Input from "@flow-builder/core/src/Blocks/Core/Inputs/Input.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import googleService from "@flow-builder/core/src/Services/External/Google.ts";
import locationService from "@flow-builder/core/src/Services/location-service.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";

const value = ref(null);
const payloadStore = usePayloadStore();
const flowStore = useFlowStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();
const inputEl = ref();

const props = defineProps({
    label: {
        type: String,
        default: ""
    },
    placeholder: {
        type: String,
        default: ""
    },
    type: {
        type: String,
        default: "text"
    },
    instance: {
        type: Object,
        default: null
    },
    innerStyles: {
        type: Object,
        default: () => ({})
    }
});

onBeforeMount(() => {
    const init = usePayload();
    init.setInitialBlockPayload(buildPayload());
});

const initialize = () => {
    if(!inputEl.value.hasOwnProperty("_assign"))
        inputEl.value._assign = (val) => value.value = val;

    const payload = payloadStore.flowPayload?.getBlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        (props.instance as InputField).id
    );

    const initialInput = payload ? payload.payload[(props.instance as InputField).backendIdentifier] : (props.instance as InputField).value ?? null;

    if (initialInput)
        value.value = initialInput;
    else
        geolocate();
};

const geolocate = () => {
    if ((props.instance as InputField).geolocateZipCode === true) {
        googleService.loadService().then(() => {
            //@ts-ignore
            locationService.locateApproximateLocation().then(address => {
                //@ts-ignore
                const zipCode = address.address_components.filter(component => component.types.includes("postal_code"))[0] ?? null;

                if (zipCode)
                    value.value =  zipCode.long_name;
                else
                    console.error('Failed to geolocate');
            })
        });
    }
}

const buildPayload = () => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [(props.instance as InputField).backendIdentifier]: value.value
    },
    (props.instance as Block).id
);

watch(() => value.value, () => payloadStore.flowPayload?.setBlockPayload(buildPayload()));

onMounted(() => initialize());

const handleKeyup = async (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
        const newEvent: SlideUpdateEvent = {
            type: SlideActionType.NextSlide,
            trigger: props.instance as BlockJson,
        }

        await flowStore.update(
            payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
            consumerStore.bearerToken,
            newEvent
        ).catch(e => {
            errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
        });
    }
}

const textAlignment = computed(() => {
    switch((props.instance as Input).textAlignment) {
        case BlockAlignment.Left:
            return "fb-text-left";
        case BlockAlignment.Right:
            return "fb-text-right";
        case BlockAlignment.Center:
        default:
            return "fb-text-center";
    }
})

</script>