<template>
    <component :is="`h${level ?? 1}`" :class="getHeadingClasses()">
        <template v-for="item in data" >
            <template v-if="item.type === 'text' && (!item.marks || item.marks.length == 0)">
                <span :style="getTextStyle(item)">
                    {{ item.text }}
                </span>
            </template>
            <template v-else-if="item.type === 'hardBreak'">
                <br>
            </template>
            <template v-else-if="item.type === 'shortcode'">
                <ShortcodeRenderer :shortcode="item.attrs?.id" :item="item" :mobile-font-size-override="mobileFontSizeOverride"/>
            </template>
            <template v-else>
                <span
                    @mouseenter="setHoveredTooltipId(item)"
                    @mouseleave="setHoveredTooltipId(null)"
                    class="fb-relative fb-z-1"
                    :class="calculateClasses(item)"
                    :style="getTextStyle(item)"
                    @click="handleClick(item)"
                >{{item.text}}<TooltipRenderer v-if="getTooltipMark(item)" :show="showHoveredTooltip(item)" :item="getTooltipMark(item)" />
                </span>
            </template>
        </template>
    </component>
</template>

<script setup lang="ts">
import ShortcodeRenderer from "../Shortcodes/ShortcodeRenderer.vue";
import {getLineHeight} from "@flow-builder/core/src/Components/Renderer/Composables/useLineHeight.ts";
import {MobileFontSizeOverride, TipTapItem} from "@flow-builder/core/src/Blocks/Core/Text/RichText.ts";
import {CSSProperties} from "vue";
import {
    getTooltipMark,
    setHoveredTooltipId, showHoveredTooltip
} from "@flow-builder/core/src/Components/Renderer/Composables/useTooltipMark.ts";
import TooltipRenderer from "@flow-builder/core/src/Components/Renderer/BlockRenderers/RichText/TooltipRenderer.vue";

interface Props {
    data: TipTapItem[],
    mobileFontSizeOverride: MobileFontSizeOverride,
    level: number,
}
const props = withDefaults(defineProps<Props>(), {
    level: 1,
});

const getTextStyle = (item) => {
    const styles: CSSProperties = {};

    if (props.mobileFontSizeOverride?.fontSize)
        styles.fontSize = props.mobileFontSizeOverride.fontSize;
    if (props.mobileFontSizeOverride?.lineHeight)
        getLineHeight({ lineHeight: props.mobileFontSizeOverride.lineHeight, fontSize: props.mobileFontSizeOverride.fontSize ?? "" }, styles);

    item.marks?.forEach(data => {
        switch (data.type) {
            case 'textStyle':
                if (!props.mobileFontSizeOverride?.fontSize && data.attrs.fontSize && data.attrs.fontSize !== 'auto')
                    styles.fontSize = data.attrs.fontSize;
                if (!props.mobileFontSizeOverride?.lineHeight && data.attrs.lineHeight)
                    getLineHeight(data.attrs, styles);
                break;
        }
    });

    return styles;
}

const calculateClasses = (item) => {
    const classes = [];

    item.marks.forEach(item => {
        switch(item.type) {
            case 'italic':
                classes.push('fb-italic');
                break;
            case 'bold':
                classes.push('fb-font-bold')
                break;
            case 'underline':
                classes.push('fb-underline')
                break;
            case 'strike':
                classes.push('fb-line-through')
                break;
            case 'textStyle':
                if (item.attrs.solColor && item.attrs.solColor !== 'auto')
                    classes.push(`fb-text-sol-${item.attrs.solColor}`);
                break;
            case 'link':
                classes.push('fb-cursor-pointer')
                break;
            case 'tooltip':
                classes.push('fb-cursor-pointer fb-text-blue-500 fb-underline fb-mr-1')
                break;
        }
    });

    return classes;
}

const getHeadingClasses = () => {
    switch(props.level) {
        case 1:
            return 'fb-text-[32px] fb-font-display';
        case 2:
            return 'fb-text-[28px] fb-font-display';
        case 3:
            return 'fb-text-[24px] fb-font-display';
        case 4:
            return 'fb-text-[20px] fb-font-display';
        case 5:
            return 'fb-text-lg fb-font-display fb-font-light';
        case 6:
        default:
            return 'fb-text-md fb-font-display fb-font-light';
    }
}

const handleClick = (item) => {
    const handleLinkClick = (url) => window.open(url, '_blank')

    item.marks.forEach(item => {
        switch(item.type) {
            case 'link':
                handleLinkClick(item.attrs.href)
                break;
        }
    });
}
</script>