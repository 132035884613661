<template>
    <div>
        <div v-for="item in tree?.content" :class="{[textAlignment]: true}">
            <HeadingRenderer v-if="item.type === 'heading'" :data="item.content ?? []" :level="item.attrs.level" :mobile-font-size-override="mobileFontSizeOverride"/>
            <ParagraphRenderer v-if="item.type === 'paragraph'" :data="item.content ?? []" :mobile-font-size-override="mobileFontSizeOverride"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";
import HeadingRenderer from "./HeadingRenderer.vue";
import ParagraphRenderer from "./ParagraphRenderer.vue";
import RichText from "@flow-builder/core/src/Blocks/Core/Text/RichText.ts";
import {BlockAlignment} from "@flow-builder/core/src/Blocks/Core/Block.ts";

const props = defineProps({
    content: {
        type: Object,
        default: null
    },
    instance: {
        type: Object,
        default: null
    },
    mobileFontSizeOverride: {
        type: Object,
        default: null,
    }
});

const tree = computed(() => props.content);

const textAlignment = computed(() => {
    switch((props.instance as RichText).textAlignment) {
        case BlockAlignment.Left:
            return "fb-text-left";
        case BlockAlignment.Right:
            return "fb-text-right";
        case BlockAlignment.Center:
        default:
            return "fb-text-center";
    }
})
</script>