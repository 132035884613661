<template>
    <div>
        <div class="fb-transition-all fb-duration-500"
             :class="[showInstallers || 'fb-pointer-events-none fb-select-none fb-blur-sm']"
        >
            <p class="fb-text-slate-500" style="font-size: 0.75rem; line-height: 1rem; text-align: justify;">
              By clicking Select and Agree below I am providing my ESIGN signature and express written consent
              agreement to permit the company, <a href="https://www.solarreviews.com/solar-companies" class="fb-underline" target="_blank">partners</a>, or companies, selected below, and parties calling on their behalf,
              to contact me at the number provided below for marketing purposes including the use of automated technology,
              SMS/MMS messages, AI generative voices, and prerecorded and/or artificial voice messages. I acknowledge my consent is not
              required to obtain any goods or service and to be connected with sellers that can fit my needs without providing content
              I can click <span class="hover:fb-cursor-pointer fb-underline" @click="() => handleSkip()">here</span>.
                <span class="hover:fb-cursor-pointer fb-underline" @click="() => handleUnselectAll()">Unselect all</span> options.
            </p>
        </div>
        <div ref="scrollIndicator" class="fb-fixed fb-inset-x-0 fb-bottom-0 fb-bg-gradient-to-b fb-from-transparent fb-to-orange-100 fb-flex fb-justify-center fb-py-8 fb-z-40">
          <svg @click="scrollToButton" class="fb-cursor-pointer fb-animate-bounce fb-w-8 fb-fill-current fb-text-orange-500 fb-z-50"  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM11.25 6.25C11.25 5.91848 11.1183 5.60054 10.8839 5.36612C10.6495 5.1317 10.3315 5 10 5C9.66848 5 9.35054 5.1317 9.11612 5.36612C8.8817 5.60054 8.75 5.91848 8.75 6.25V10.7325L7.13375 9.11625C6.898 8.88855 6.58224 8.76256 6.2545 8.76541C5.92675 8.76826 5.61324 8.89972 5.38148 9.13148C5.14972 9.36324 5.01826 9.67675 5.01541 10.0045C5.01256 10.3322 5.13855 10.648 5.36625 10.8837L9.11625 14.6337C9.35066 14.8681 9.66854 14.9997 10 14.9997C10.3315 14.9997 10.6493 14.8681 10.8837 14.6337L14.6337 10.8837C14.8614 10.648 14.9874 10.3322 14.9846 10.0045C14.9817 9.67675 14.8503 9.36324 14.6185 9.13148C14.3868 8.89972 14.0732 8.76826 13.7455 8.76541C13.4178 8.76256 13.102 8.88855 12.8663 9.11625L11.25 10.7325V6.25Z"/>
          </svg>
        </div>
        <div class="fb-p-2 fb-m-2 fb-text-center">
          <label class="fb-block fb-mb-3 fb-font-medium">Enter phone to see installers who will give you prices:</label>
          <div class="fb-flex fb-items-center fb-gap-x-3 fb-max-w-[20rem] fb-mx-auto fb-relative">
            <div class="fb-h-[95%] fb-left-[1px] fb-inline-flex fb-items-center fb-p-2 fb-rounded-l-md fb-border-r fb-absolute fb-bg-slate-200 fb-bg-opacity-30">+1</div>
            <input
              class="fb-flex-1 fb-border fb-border-gray-200 fb-pr-4 fb-pl-12 fb-py-3 fb-rounded"
              type="tel"
              pattern="[0-9]{10}"
              maxlength="10"
              v-model="phone"
              placeholder="Phone Number"
              autofocus
              @input="handlePhoneInput"
            />
          </div>
          <div v-if="errorMessage" class="fb-text-sm fb-text-red-600 fb-pt-1 fb-min-h-8"
               :class="[errorMessage || 'fb-opacity-0']"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="fb-grid fb-grid-cols-1 md:fb-grid-cols-2 fb-gap-3 fb-mb-6 fb-transition-all fb-duration-500"
             :class="[showInstallers || 'fb-pointer-events-none fb-select-none fb-blur-sm']"
        >
            <div v-for="(item, index) in callItems" :key="item.id"
                 class="fb-gap-3 fb-flex fb-items-center fb-justify-start  md:fb-border-t md:fb-border-l md:fb-border-r md:fb-border-b md:fb-p-3 md:fb-rounded-lg md:fb-border-gray-200 md:fb-bg-sol-custom-3"
            >
                <div class="fb-relative fb-inline-flex fb-items-center">
                    <input class="fb-cursor-pointer flex-shrink-0 fb-min-w-[20px] fb-min-h-[20px] hover:fb-bg-blue-200 hover:checked:fb-bg-blue-600 fb-appearance-none fb-border-2 fb-rounded-sm fb-border-blue-500 checked:fb-bg-blue-500"
                           @click="() => handleConsentUpdate()"
                           v-model="callItems[index].checked"
                           type="checkbox"
                    >
                    <svg v-if="callItems[index].checked" class="fb-pointer-events-none fb-absolute fb-inset-0 fb-w-full fb-h-full fb-p-1" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.91869 7.45694L1.17719 4.49588L0 5.76734L3.91869 9.99988L12 1.27135L10.8228 -0.00012207L3.91869 7.45694Z" fill="white"/>
                    </svg>
                </div>
                <div>
                    <div class="fb-text-sm fb-font-bold">{{ item.name }}</div>
                    <div v-if="instance.showRatings" class="fb-flex fb-items-center">
                        <div v-if="!item.rating">
                            <p class="fb-text-sm fb-text-slate-500 fb-italic">No reviews yet</p>
                        </div>
                        <div class="fb-flex fb-items-center" v-else>
                            <StarRating star-width="fb-w-24 md:fb-w-32" :consumerRating="item.rating" />
                            <div class="fb-text-sm fb-ml-3">
                                <span class="fb-font-semibold">{{ item.rating ? item.rating.toFixed(1) : '-' }}</span>
                                <span class="fb-text-sm fb-text-slate-400 fb-ml-1">({{ item.reviews }})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fb-flex fb-flex-wrap fb-items-center fb-justify-center fb-gap-3 fb-mt-6">
            <button @click="() => handleCombinedButtonClick()"
                    class="fb-text-white fb-font-semibold fb-w-full md:fb-w-auto fb-px-8 fb-py-3 fb-rounded-[4px] fb-cursor-pointer fb-transition-colors fb-duration-500"
                    :class="[validPhoneLength ? 'fb-bg-orange-500 hover:fb-bg-orange-400' : 'fb-bg-gray-200 fb-cursor-not-allowed']"
                    :disabled="!validPhoneLength"
                    ref="observeButton"
            >
                {{ showInstallers ? 'Select and Agree' : 'Show Prices' }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted, ref, Ref } from "vue";
import OptInCombo, {EngineSafeCallItem} from "@flow-builder/core/src/Blocks/Core/Buttons/OptInCombo.ts";
import {EngineOutputs, useEngineStore} from "../../../Stores/engines.ts";
import StarRating from "@flow-builder/core/src/Components/StarRating.vue";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {useErrorStore} from "../../../Stores/errors.ts";

interface Props {
    instance: OptInCombo,
}
const props = defineProps<Props>();

const engineStore = useEngineStore();
const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

const callItems: Ref<EngineSafeCallItem[]> = ref([]);
const engineName: Ref<string> = ref("");
const outputName: Ref<string> = ref("");
const phone: Ref<string> = ref("");
const saving = ref(false);
const observeButton = ref<HTMLInputElement | null>(null)
const scrollIndicator = ref<HTMLInputElement | null>(null)

const showInstallers = ref(false);
const errorMessage: Ref<string> = ref('');

onMounted(() => initialize());

const validAreaCodes = [205,251,256,334,907,480,520,602,623,928,479,501,870,209,213,310,323,408,415,424,510,530,559,562,619,626,650,661,707,714,760,805,818,831,858,909,916,925,949,951,303,719,720,970,203,860,302,202,689,239,305,321,352,386,407,561,727,754,772,786,813,850,863,904,941,954,762,229,404,478,678,706,770,912,808,208,730,779,217,224,309,312,447,618,630,708,773,815,847,219,260,317,574,765,812,319,515,563,641,712,316,620,785,913,270,502,606,859,225,318,337,504,985,207,240,301,410,443,339,351,413,508,617,774,781,857,978,231,248,269,313,517,586,616,734,810,906,947,989,218,320,507,612,651,763,952,228,601,662,769,314,417,573,636,660,816,406,308,402,702,775,603,201,551,609,732,848,856,862,908,973,505,575,212,315,347,516,518,585,607,631,646,716,718,845,914,917,252,336,704,828,910,919,980,701,216,234,330,419,440,513,567,614,740,937,405,580,918,503,541,971,215,267,412,484,570,610,717,724,814,878,401,803,843,864,605,423,615,731,865,901,931,210,214,254,281,325,361,409,430,432,469,512,682,713,806,817,830,832,903,915,936,940,956,972,979,435,801,802,276,434,540,571,703,757,804,206,253,360,425,509,681,304,262,414,608,715,920,307];

const validPhoneLength: ComputedRef<boolean> = computed(() => phone.value.replace(/\D/g, '')?.length === 10);

const validateAreaCode = (): boolean => {
    const trimmed = phone.value.replace(/\D/g, '');
    return trimmed.length === 10
        && validAreaCodes.includes(Number(trimmed.slice(0,3)));
}

const initialize = () => {
    engineName.value = props.instance.dataSourceIdentifierData.engine ?? "";
    outputName.value = props.instance.dataSourceIdentifier ?? "";
    if (engineStore.getEngineInputByKey('phone')) {
        phone.value = engineStore.getEngineInputByKey('phone')
        showInstallers.value = true;
    }

    handleOutputUpdate(engineStore.outputs);
    handleConsentUpdate();

    selectAll();

    createObserver();

    if(window.innerWidth < 768) {
      createButtonObserver();
    }
}

const handlePhoneInput = () => {
    errorMessage.value = '';
}

const handleConsentUpdate = () => {
    payloadStore.flowPayload?.setBlockPayload(buildPayload());
}

const handleOutputUpdate = (outputs: EngineOutputs) => {
    if (outputs[engineName.value]?.[outputName.value]?.value?.length) {
        updateCallItems(outputs[engineName.value][outputName.value].value);
    }
}

const updateCallItems = (values: EngineSafeCallItem[]) => {
    callItems.value = values;
}

const buildPayload = () => {
    return new BlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        {
            [(props.instance as OptInCombo).backendIdentifier]: callItems.value.map((value: EngineSafeCallItem) => {
                return {
                    reference: value.reference,
                    checked: value.checked ?? false
                }
            }),
            phone: phone.value,
        },
        (props.instance as Block).id
    );
}

const selectAll = () => {
    callItems.value = callItems.value.map((value: EngineSafeCallItem) => {
        value.checked = true;
        return value;
    });
}

const unselectAll = () => {
    callItems.value = callItems.value.map((value: EngineSafeCallItem) => {
        value.checked = false;
        return value;
    });
}

const handleSkip = () => {
    unselectAll();
    handleContinue(true);
}

const handleUnselectAll = () => {
    unselectAll();
    handleConsentUpdate();
}

const handleCombinedButtonClick = () => {
    if (!showInstallers.value) {
        if (validateAreaCode()) {
            showInstallers.value = true;
        }
        else {
            errorMessage.value = "Please enter a valid phone number"
        }
    }
    else
        handleContinue();
}

const handleContinue = async (skip: boolean = false) => {
    handleConsentUpdate();

    if (saving.value) return;

    saving.value = true;

    errorStore.slideErrors.resetSlideError(flowStore.sliderService.currentSlide?.id ?? '');

    const newEvent: SlideUpdateEvent = {
        type: SlideActionType.NextSlide,
        actions: skip ? [{
            type: SlideActionType.GoToLastSlide,
            target: null
        }] : []
    }

    const updateSuccessful = await flowStore.update(
        payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
        consumerStore.bearerToken,
        newEvent,
    ).catch(e => {
        errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
    });

    if (!updateSuccessful) {
        saving.value = false;
        return;
    }
    else {
        setTimeout(() => {
            saving.value = false;
        }, 500);
    }
};

const inputsChecked = computed(() => {
    return callItems.value.filter((item) => item.checked)
})

function createObserver() {
    let observer;
    observer = new IntersectionObserver(handleIntersect, {rootMargin: "-20px"});
    observer.observe(observeButton.value);
}

function handleIntersect(entries) {
    entries.forEach((entry) => {
        if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
            scrollIndicator.value.classList.add('fb-hidden');
        }
        else {
            scrollIndicator.value.classList.remove('fb-hidden');
        }
    });
}

function createButtonObserver() {
  let observer;
  const observeButton = document.querySelector("#screen-aware-button");
  observer = new IntersectionObserver(handleButtonIntersect, {rootMargin: "-75px"});
  observer.observe(observeButton);
}

function handleButtonIntersect(entries) {
  entries.forEach((entry) => {
    if (entry.intersectionRatio <= 0) {
      entry.target.classList.add('fb-fixed', 'fb-bottom-0', 'fb-bg-white', 'fb-inset-x-0', 'fb-z-[1000]')
    }
  });
}

function scrollToButton() {
    observeButton.value.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center'  });
}

function formatPhoneNumber(phoneNumberString: Number) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

</script>
