import { defineStore } from "pinia";
import { computed, ComputedRef, ref, Ref } from "vue";

export const useCompatibilityStore = defineStore('browserCompatibility', () => {
    const compatibilityMessages: string[] = [];
    const hasCompatibilityIssues: ComputedRef<boolean> = computed(() => compatibilityMessages.length > 0);
    const disableVectorMap: Ref<boolean> = ref(false);

    const userAgentParts: {[key: string]: string} = {};

    // Just arbitrarily going for 2+ years old
    const minimumVersions: {[key: string]: number} = {
        Chrome: 102,
        Firefox: 107,
        Opera: 93,
    };

    const initialize = (): void => {
        getUserAgentParts();
        checkBrowserSupportsHTMLDialogElementAPI();
        checkBrowserSupportsVectorMap();
    }

    /**
     * Conditions for determining this properly are not really known - needs further testing
     * Symptom is street names (and other labels) briefly appearing then disappearing from google map
     * Console will spam errors relating to LabellerCrash / shared-label-worker.js
     */
    const checkBrowserSupportsVectorMap = () => {
        const browser = getBrowserString();
        if (browser === 'Firefox') {
            const version = getBrowserVersion(browser);
            if (version && version <= 97) {
                disableVectorMap.value = true;
                compatibilityMessages.push("Current browser does not support google vector maps");

                return false;
            }
        }
        return true;
    }

    /**
     * Missing API in older browsers causes google.maps autocomplete to fail
     * This hack will allow autocomplete to work and the address to update, however some parts of the map may not work (e.g. keyboard shortcuts menu)
     */
    const checkBrowserSupportsHTMLDialogElementAPI = (): boolean => {
        if (window.HTMLDialogElement == undefined) {
            class DummyHTMLDialogElement extends HTMLElement {}
            window.HTMLDialogElement = DummyHTMLDialogElement;
            window.HTMLElement.prototype.open = false;
            window.HTMLElement.prototype.close = function() { console.warn('Browser does not support HTMLDialogElement') };
            window.HTMLElement.prototype.show = function() { console.warn('Browser does not support HTMLDialogElement.') };
            window.HTMLElement.prototype.showModal = function() { console.warn('Browser does not support HTMLDialogElement.') };

            compatibilityMessages.push("Current browser does not support the HTMLDialogElement API");

            return false;
        }

        return true;
    }

    /**
     * Not currently used, but we could potentially encourage users to update older browsers
     * or at least warn of sub-optimal performance and features
     */
    const userShouldUpdateBrowser = (): boolean => {
        if (!Object.keys(userAgentParts).length)
            getUserAgentParts();

        const upgradeableBrowser = getUpgradeableBrowser();

        return upgradeableBrowser
            ? versionIsOutdated(upgradeableBrowser)
            : false;
    }

    const versionIsOutdated = (browser: string): boolean => {
        const version = getBrowserVersion(browser);
        const minimum = minimumVersions[browser];

        if (version && minimum)
            return version < minimum;
        else
            return false;
    }

    /**
     * Returns the major version from userAgent
     * @param browser
     */
    const getBrowserVersion = (browser: string): number|null => {
        const versionString = userAgentParts[browser]?.match(/^\d+/)?.[0];

        return versionString
            ? Number(versionString)
            : null;
    }

    /**
     * Ignore Safari as the user may need to update the OS to be eligible for browser updates, gg Apple
     */
    const getUpgradeableBrowser = (): string|null => {
        const browser = getBrowserString();

        return (browser && browser in minimumVersions)
            ? browser
            : null;
    }

    const getBrowserString = (): string|null => {
        const keys = Object.keys(userAgentParts);
        if (keys.includes('Firefox')) {
            return keys.includes('Seamonkey')
                ? null
                : 'Firefox';
        }
        else if (keys.includes('Safari')) {
            return (keys.includes('Chrome') || keys.includes('Chromium'))
                ? null
                : 'Safari'
        }
        else if (keys.includes('Chrome')) {
            return (keys.includes('Chromium') || keys.findIndex(key => /^Edg.*/.test(key)) === -1)
                ? null
                : 'Chrome'
        }
        else if (keys.includes('Opera') || keys.includes('OPR')) {
            return 'Opera';
        }

        return null;
    }

    const getUserAgentParts = (): void  => {
        const userAgent = window.navigator.userAgent;
        const parts = Array.from(userAgent.matchAll(/(\w+)\/([\d.]+)/g));
        parts.forEach((part) => {
            userAgentParts[part[1]] = part[2];
        });
    }

    return {
        compatibilityMessages,
        hasCompatibilityIssues,
        disableVectorMap,

        initialize,
        userShouldUpdateBrowser,
    }
});