export type BranchHistoryStorage = {
    branchHistory: string[];
    historyIndex: number;
}

export class BranchHistory {

    protected branchHistory: string[] = [];
    protected historyIndex = -1;

    protected setIndexToEnd(): void {
        this.historyIndex = this.branchHistory.length - 1;
    }

    protected indexAtEnd() {
        return this.historyIndex === this.branchHistory.length - 1;
    }

    public makeBranchHistoryPoint(slideId: string): void {
        if (!this.indexAtEnd()) {
            this.branchHistory = this.branchHistory.slice(0, this.historyIndex + 1);
        }
        this.branchHistory.push(slideId);
        this.setIndexToEnd();
    }

    public getReturnSlideFromEndOfBranch(): string {
        const targetSlideId = this.branchHistory[this.historyIndex];
        if (this.historyIndex > 0) {
            this.historyIndex -= 1;
        }
        else this.historyIndex = -1;

        return targetSlideId;
    }

    public goBack(fromSlideId: string | null): void {
        if (this.branchHistory.length && fromSlideId) {
            const targetIndex = Math.max(this.historyIndex + 1, 0);
            if (fromSlideId === this.branchHistory[targetIndex]) {
                this.historyIndex += 1;
            }
        }
    }

    public getHistory(): string[] {
        return this.branchHistory;
    }

    public getStorage(): BranchHistoryStorage {
        return {
            branchHistory: this.branchHistory,
            historyIndex: this.historyIndex,
        }
    }

    public setFromStorage(storedHistory: BranchHistoryStorage) {
        this.branchHistory = storedHistory.branchHistory;
        this.historyIndex = storedHistory.historyIndex;
    }

}