import {defineStore} from "pinia";
import {ref} from "vue";

export const useConsumerStore = defineStore('consumer', () => {
    const bearerToken = ref('');
    const tokenExpiresAt = ref(null);

    return {
        bearerToken,
        tokenExpiresAt
    }
});