<template>
    <div :id="[props.instance.name === 'Screen Aware Button' ? 'screen-aware-button' : '']"
         :class="[buttonLocked ? 'fb-invisible' : '' ]">
        <div
            class="fb-cursor-pointer fb-flex fb-gap-2 fb-rounded fb-px-4 fb-py-3 fb-w-full fb-items-center fb-transition-opacity fb-duration-200"
            :class="[gradient ? gradientClasses() : `fb-bg-sol-${color}`, `hover:fb-bg-sol-${color}-light`, `fb-text-sol-${textColor}`, {'fb-pointer-events-none fb-opacity-20': saving}, borderClasses]" :style="innerStyles"
            @click="handleClick"
        >
            <div class="fb-text-left fb-w-4">
                <svg v-if="icon != null && iconSide == ButtonIconSide.Left" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="fb-w-4 fb-h-4" v-html="icon">
                </svg>
            </div>

            <div class="fb-text-center fb-flex-1">
                {{ content }}
            </div>

            <div class="fb-flex fb-justify-end fb-w-4">
                <svg v-if="icon != null && iconSide == ButtonIconSide.Right" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="fb-w-4 fb-h-4" v-html="icon">
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button, {ButtonIconSide} from "@flow-builder/core/src/Blocks/Core/Buttons/Button.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {computed, onMounted, ref} from "vue";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {defaultTheme, ThemeGradient} from "@flow-builder/core/src/Styling/Theme.ts";
import {BaseAction, SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import Block, {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {LegacyAction} from "@flow-builder/core/src/Blocks/Core/Actions/Action.ts";

const flowStore = useFlowStore();
const consumerStore = useConsumerStore();
const payloadStore = usePayloadStore();
const errorStore = useErrorStore();
const saving = ref(false);

interface Props {
    content: string,
    color: string,
    gradient: string|null,
    textColor: string,
    iconSide: SlideActionType,
    icon: string|null,
    instance: BlockJson,
    innerStyles: {[key: string]: any},
    actions: BaseAction[],
    legacyActions: LegacyAction[]|null,
}
const props = withDefaults(defineProps<Props>(), {
    content: "Button",
    color: "blue",
    textColor: "black",
    iconSide: SlideActionType.NextSlide,
    innerStyles: () => ({}),
    actions: () => [],
});

onMounted(() => {
    if (props.legacyActions) props.actions.push(...Block.convertLegacyBlockActions(props.legacyActions));

    if(props.instance.name === 'Screen Aware Button') {
        if(window.innerWidth < 768) {
            createObserver();
        }
    }
});

function createObserver() {
    let observer;
    const observeButton = document.querySelector("#screen-aware-button");
    observer = new IntersectionObserver(handleIntersect, {rootMargin: "-75px"});
    observer.observe(observeButton);
}
function handleIntersect(entries) {
    entries.forEach((entry) => {
        if (entry.intersectionRatio <= 0) {
            entry.target.classList.add('fb-fixed', 'fb-bottom-0', 'fb-bg-white', 'fb-inset-x-0', 'fb-z-[1000]')
        }
    });
}

const buttonLocked = computed(() => {
    if (flowStore.previousButtonHidden || flowStore.nextButtonHidden) {
        const isBackButton = props.actions && !!props.actions.find(action => action.type === SlideActionType.PreviousSlide);
        const isNextButton = props.actions && !!props.actions.find(action => action.type === SlideActionType.NextSlide);
        return (flowStore.previousButtonHidden && isBackButton) || (flowStore.nextButtonHidden && isNextButton);
    }
    return false
});

const handleClick = async () => {
    if (saving.value) return;

    saving.value = true;

    errorStore.slideErrors.resetSlideError(flowStore.sliderService.currentSlide?.id ?? '');

    const newEvent: SlideUpdateEvent = {
        type: props.actions[0]?.type ?? SlideActionType.None,
        trigger: props.instance as BlockJson,
        actions: props.actions,
    }

    const updateSuccessful = await flowStore.update(
        payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
        consumerStore.bearerToken,
        newEvent,
    ).catch(e => {
        errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
    });

    if (!updateSuccessful) {
        saving.value = false;
        return;
    }
    else {
        setTimeout(() => {
            saving.value = false;
        }, 500);
    }
};



const gradientClasses = () => {
    const gradient: ThemeGradient = defaultTheme.colors?.[`gradient_${props.gradient}`];
    if (gradient) {
        return `fb-bg-gradient-to-b fb-from-${gradient.fromName} fb-to-${gradient.toName}`;
    }
}

const borderClasses = computed(() => (props.instance as Button).borderColor ? `fb-border fb-border-sol-${(props.instance as Button).borderColor}` : ``)
</script>